<template>
  <div class="h-full w-full translate-x-0 transition duration-700 ease-in-out" id="notification">
    <div v-if="loading">Loading...</div>
    <div v-else-if="getNotifications.length">
      <div class="m-3 flex items-center justify-between">
        <p tabindex="0" class="text-2xl font-semibold leading-6 text-gray-300 focus:outline-none">Notifications</p>
      </div>
      <div class="max-h-[300px] overflow-y-scroll" style="scrollbar-width: thin">
        <div v-for="(notification, index) in getNotifications" :key="index" class="px-2 pb-3">
          <div class="flex w-full items-center rounded bg-arco-dark-card-2 p-3">
            <div class="pr-2">
              <div tabindex="0" aria-label="group icon" role="img" class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-gray-700 focus:outline-none">
                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg3.svg" alt="icon" />
              </div>
            </div>
            <div class="w-full">
              <div class="flex w-full items-center justify-between">
                <div class="flex flex-col">
                  <p tabindex="0" class="text-sm leading-none focus:outline-none">{{ notification.message }}</p>
                  <p tabindex="0" class="pt-1 text-xs leading-3 text-gray-500 focus:outline-none">{{ formatDateRelative(notification.created_at) }}</p>
                </div>
                <div class="flex cursor-pointer flex-col items-center border-gray-300 dark:border-gray-700" @click="removeNotification(notification.id)">
                  <el-icon class="ai ai-Remove-1" size="18"></el-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>No notifications</div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { formatDistance } from "date-fns";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("notification", ["getNotifications"]),
  },
  methods: {
    ...mapActions("notification", ["fetchNotifications", "updateNotification", "deleteNotification"]),
    ...mapMutations("notification", ["clearAll"]),
    formatDateRelative(date) {
      return formatDistance(new Date(date), new Date(), {
        addSuffix: true,
      });
    },
    async toggleRead(id, isRead) {
      try {
        await this.updateNotification({ id, isRead });
      } catch (error) {
        console.error("Error updating notification read status:", error);
      }
    },
    async removeNotification(id) {
      try {
        await this.deleteNotification(id);
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    },
  },
  mounted() {
    this.loading = true;
    this.fetchNotifications().finally(() => {
      this.loading = false;
    });
  },
};
</script>

<!-- ... styles ... -->
