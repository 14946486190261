import insert_account_kpi from "@/graphql/analytics/insert_account_kpi.gql";
import { ElNotification } from "element-plus";
import { query } from "@/api";
const defaultState = {
    currentStep: 1,
    products: [],
    capabilities: [],
    kpi_ref: null,
    metric_id: null,
    formData: {
        account_id: null,
        capability_id: null,
        base_field: null,
        kpi_type: null,
        modifier_field: null,
        product_id: null,
        widget_type: null,
        target: null,
        suffix: null,
        default_name: null,
        custom_name: null,
        improvement_direction: null,
        colour_map: null
    },
};
const state = { ...defaultState };
const mutations = {
    resetState(state) {
        Object.assign(state, defaultState);
    },
    updateStep(state, step) {
        state.currentStep = step;
    },
    updateProduct(state, data) {
        console.log(data)
        state.products = data.products;
        state.capabilities = data.capabilities;
    },
    updateKpiRef(state, data) {
        state.kpi_ref = data;
    },
    updateFormData(state, data) {
        state.formData = { ...state.formData, ...data };
    },
};

const actions = {
    resetState({ commit }) {
        commit("resetState");
    },
    updateStep: ({ commit }, step) => commit("updateStep", step),
    updateFormData: ({ commit }, data) => commit("updateFormData", data),
    async insert_account_kpi({ state, dispatch }) {
        try {
            const { data } = await query({
                query: insert_account_kpi,
                variables: {
                    object: state.formData,
                },
            });
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    currentStep(state) {
        return state.currentStep;
    },
    formData: (state) => state.formData,
    capabilities: (state) => state.capabilities,
    products: (state) => state.products,
    kpi_ref: (state) => state.kpi_ref,
    kpi_type: (state) => state.formData.kpi_type,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
