<template>
  <div class="login-container">
    <AuthAnim></AuthAnim>

    <div class="container relative flex-col items-center justify-center sm:hidden sm:h-screen md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div class="relative hidden flex-col items-center p-10 text-white sm:h-full lg:flex">
        <div class="bg-card/50 absolute inset-0" />

        <div class="relative z-20 flex h-full items-center text-lg">
          <div class="flex flex-col space-y-2">
            <div class="flex flex-row justify-center pt-3">
              <img :src="VUE_APP_LOGO" class="max-h-[150px] self-start" alt="Dynamic Image" />
            </div>
          </div>
        </div>
      </div>
      <div class="transparency flex h-screen lg:p-8">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthAnim from "@/views/Auth/AuthAnim.vue";
export default {
  components: { AuthAnim },
  data: () => ({
    logo_login: import.meta.env.VUE_APP_LOGO_LOGIN || "https://cdn-uk1.redsmart.app/arco/environments/b8c1cf27-5e2e-41ed-a67c-26783b169322/arco_logo.svg",
    primary: import.meta.env.VUE_APP_PRIMARY_COLOR || "#43c6fd",
  }),
  computed: {
    ...mapGetters("user", ["msspView"]),
    ...mapGetters("ui", ["VUE_APP_PRIMARY_COLOR", "VUE_APP_PRIMARY_HIGHLIGHT_COLOR", "VUE_APP_SECONDARY_COLOR", "VUE_APP_LOGO", "VUE_APP_LOGO_LOGIN", "VUE_APP_LOGO_DARK", "VUE_APP_DOC_TEMPLATE"]),
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.transparency {
  background: rgba(27, 37, 42, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(12px);
  border-left: 1px solid rgba(50, 73, 85, 0.43);
}
.wrapper {
  box-sizing: content-box;
  height: 50px;
  padding: 50px 30px;
  display: flex;
}
.words {
  overflow: hidden;
  height: 100%;
}

.word-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: spin_words 20s infinite;
}

span {
  text-align: center;
}
@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }
  25% {
    transform: translateY(-100%);
  }
  35% {
    transform: translateY(-212%);
  }
  50% {
    transform: translateY(-200%);
  }
  60% {
    transform: translateY(-312%);
  }
  75% {
    transform: translateY(-300%);
  }
  85% {
    transform: translateY(-412%);
  }
  100% {
    transform: translateY(-400%);
  }
}
.login-content {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
}
</style>
