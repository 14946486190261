import axios from "axios";
import { ref, onMounted, onUnmounted } from "vue";
import { createClient } from "graphql-ws";
import { print } from "graphql/language/printer";
import router from "./router";

const isProduction = import.meta.env.MODE === "production";

function getCallerInfo() {
  const err = new Error();
  const stack = err.stack.split("\n");
  // stack[0] will be the current function, so we take stack[1]
  const callerLine = stack[2];
  return callerLine.trim();
}

function formatGraphQLQuery(query) {
  return query
    .replace(/\\n/g, "\n") // replace escaped newlines with actual newlines
    .replace(/\\"/g, '"') // replace escaped double quotes with actual double quotes
    .trim(); // trim whitespace from the beginning and end
}

// Helper function to determine color based on response time.
function getResponseTimeColor(time) {
  if (time < 300) return "green"; // Instantaneous to Immediate
  if (time < 1000) return "yellowgreen"; // Acceptable Delay
  if (time < 2000) return "orange"; // Threshold of Acceptance
  return "red"; // Potential Frustration
}

// Helper function to determine color based on response size in bytes.
function getResponseSizeColor(size) {
  const sizeInMB = size / (1024 * 1024);
  if (sizeInMB < 0.1) return "green"; // General API Call (on the smaller side)
  if (sizeInMB < 1) return "yellowgreen"; // General API Call (larger but acceptable)
  if (sizeInMB < 5) return "orange"; // Threshold of Caution
  return "red"; // Potential Problem
}

export function getHeaders() {
  if (!checkJWTExists()) {
    return null;
  } else if (checkJWTExpired()) {
    localStorage.removeItem("jwt_token");
    router.push("/auth/login");
    return null;
  }
  const token = localStorage.getItem("jwt_token");

  // return the headers to the context so httpLink can read them

  return {
    Authorization: token ? `Bearer ${token}` : "",
  };
}

function checkJWTExpired() {
  const token = localStorage.getItem("jwt_token");
  if (typeof token !== "string") {
    return false;
  }
  const jwt = JSON.parse(atob(token.split(".")[1]));
  const jwtExp = jwt.exp;
  const expiryDate = new Date(0);
  expiryDate.setUTCSeconds(jwtExp);
  const now = new Date();
  if (now > expiryDate) {
    return true;
  }
  return false;
}

function checkJWTExists() {
  const token = localStorage.getItem("jwt_token");
  if (!token) {
    return false;
  }
  return true;
}

export async function query({ query, variables }) {
  try {
    const headers = getHeaders();
    if (!headers) return;
    const parseAST = print(query);
    // Extract operation name from the AST
    const operationName = query.definitions[0]?.name?.value;
    if (!query.loc?.source?.body) {
      return console.error("GraphQL query not properly defined");
    }
    let queryDef = {
      query: parseAST,
    };
    if (variables) {
      queryDef.variables = variables;
    }
    const startTime = new Date();
    const { data } = await axios({
      url: `https://api.arcocyber.com/v1/graphql?queryName=${operationName}`,
      method: "POST",
      headers: headers,
      data: queryDef,
    });
    const endTime = new Date();
    const duration = endTime - startTime;

    const responseSize = JSON.stringify(data).length;

    const timeColor = getResponseTimeColor(duration);
    const sizeColor = getResponseSizeColor(responseSize);

    if (!isProduction) {
      console.groupCollapsed(
        `GraphQL Operation: ${operationName} [Size: %c${(
          responseSize /
          (1024 * 1024)
        ).toFixed(2)}MB%c] [Time: %c${duration}ms%c]`,
        `color: ${sizeColor}`,
        "color: unset",
        `color: ${timeColor}`,
        "color: unset"
      );
      console.log("Request:", formatGraphQLQuery(queryDef.query));
      console.log("Variables:", variables);
      console.log("Response:", data);
      console.log("Call Stack:", getCallerInfo());
      console.groupEnd();
    }

    if (data.errors) {
      data.errors.forEach((e) => {
        if (e.message === "Could not verify JWT: JWTExpired") {
          router.push("/login"); // redirect user to login
        }
        console.log(e);
      });
    }

    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

const wsClient = createClient({
  url: "wss://api.arcocyber.com/v1/graphql",
  connectionParams: async () => {
    return {
      headers: getHeaders(),
    };
  },
});

function subscribe(query, variables, onNext, onError, onComplete) {
  const operation = {
    query: query.loc.source.body,
    variables,
  };

  return wsClient.subscribe(operation, {
    next: onNext,
    error: onError,
    complete: onComplete,
  });
}

export function useSubscription(query, fieldName) {
  const data = ref(null);
  const error = ref(null);
  const isLoading = ref(false);

  const handleData = (receivedData) => {
    data.value = receivedData.data[fieldName];
  };

  const handleError = (err) => {
    console.error("Subscription error:", err);
    error.value = err;
  };

  const handleComplete = () => {
    console.log("Subscription complete");
  };

  let unsubscribe;

  onMounted(async () => {
    await new Promise((resolve) => {
      if (wsClient.status === wsClient.OPEN) {
        resolve();
      } else {
        wsClient.on("connected", resolve);
      }
    });

    isLoading.value = true;
    unsubscribe = subscribe(
      query,
      null,
      handleData,
      handleError,
      handleComplete
    );
  });

  onUnmounted(() => {
    if (unsubscribe) {
      unsubscribe();
    }
  });

  return { data, error };
}
