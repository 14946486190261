import { query } from "@/api";
import get_subscriptions from "@/graphql/administration/get_all_subscriptions.gql";
import get_modules_features from "@/graphql/administration/get_all_modules_features.gql";
const state = {
  subscriptions: null,
};

const mutations = {
  SET_SUBSCRIPTIONS(state, data) {
    state.subscriptions = data;
  },
};

const actions = {
  async getSubscriptions({ commit }) {
    try {
      const [subscriptionResponse, modulesFeaturesResponse] = await Promise.all(
        [
          query({ query: get_subscriptions }),
          query({ query: get_modules_features }),
        ]
      );

      const allModules = modulesFeaturesResponse.data.modules;
      const subscriptions = subscriptionResponse.data.subscription;

      if (!Array.isArray(subscriptions)) {
        console.error("Unexpected data structure:", subscriptions);
        return;
      }

      const updatedSubscriptions = subscriptions.map((sub) => ({
        ...sub,
        modules: allModules.map((allMod) => {
          const subscribedMod = sub.subscription_modules.find(
            (subMod) => subMod.module_id === allMod.id
          );
          const active = !!subscribedMod;

          const features = allMod.features.map((allFeat) => {
            const subscribedFeat = sub.subscription_features.find(
              (subFeat) =>
                subFeat.feature &&
                subFeat.feature.id === allFeat.id &&
                subFeat.feature.module_id === allMod.id
            );
            const activeFeat = !!subscribedFeat;

            return {
              ...allFeat,
              active: activeFeat,
            };
          });

          return {
            ...allMod,
            features,
            active,
          };
        }),
      }));

      commit("SET_SUBSCRIPTIONS", updatedSubscriptions);
    } catch (error) {
      console.error("Error fetching subscriptions and modules:", error);
    }
  },
};

const getters = {
  subcriptions: (state) => state.subscriptions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
