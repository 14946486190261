import { query } from "@/api";
import { v4 as uuidv4 } from "uuid";

const defaultStoreState = () => ({
  logicalOperators: [
    {
      value: "_eq",
      label: "EQUAL TO",
    },
    {
      value: "_neq",
      label: "NOT EQUAL TO",
    },
    {
      value: "_gt",
      label: "ABOVE",
    },
    {
      value: "_lt",
      label: "BELOW",
    },
    {
      value: "_gte",
      label: "ABOVE OR EQUAL TO",
    },
    {
      value: "_lte",
      label: "BELOW OR EQUAL TO",
    },
    {
      value: "_sw",
      label: "STARTS WITH",
    },
    {
      value: "_ew",
      label: "ENDS WITH",
    },
  ],
  iconData: [],
  changes: 0,
  settingsChanges: 0,
  selectedSettings: null,
  activeProductIndex: 0,
});
const state = defaultStoreState();

const getters = {
  logicalOperators: (state) => state.logicalOperators,
};

const actions = {
  addCondition({ commit }, { metric }) {
    commit("ADD_CONDITION", metric);
  },
  removeCondition({ commit }, { metric, id }) {
    commit("REMOVE_CONDITION", { metric, id });
  },
  insertIconConditions({ commit, state }) {
    // Logic to save icon conditions
    // Handle API calls, error handling, etc.
    commit("UPDATE_CHANGES", 0); // Reset changes after saving
  },
};

const mutations = {
  SET_SELECTED_SETTINGS(state, value) {
    state.selectedSettings = value;
  },
  ADD_CONDITION(state, metric) {
    const condition = {
      id: uuidv4(),
      operator: "_eq",
      children: [],
      value: 0,
    };
    state.iconData[state.activeProductIndex][metric].push(condition);
    state.changes++;
  },
  REMOVE_CONDITION(state, { metric, id }) {
    const idx = state.iconData[state.activeProductIndex][metric].findIndex(
      (d) => d.id === id
    );
    if (idx !== -1) {
      state.iconData[state.activeProductIndex][metric].splice(idx, 1);
    }
    state.changes++;
  },
  UPDATE_CHANGES(state, value) {
    state.changes = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
