import { query } from "@/api";
import gql_get_widget_definitions from "@/graphql/performance_metric/get_all.gql";
import gql_get_products from "@/graphql/performance_metric/get_products.gql";
import gql_update_account from "@/graphql/account/update_account.gql";
import gql_update_tenant from "@/graphql/tenant/update_tenant.gql";
import gql_widget_template from "@/graphql/performance_metric/widget_template.gql";
import gql_submit_widget_template from "@/graphql/performance_metric/submit_widget.gql";
import gql_update_widget_template from "@/graphql/performance_metric/update_widget.gql";
import gql_insert_dashboard_template from "@/graphql/performance_dashboard_template/insert_performance_dashboard_template.gql";
import gql_delete_dashboard_template from "@/graphql/performance_dashboard_template/delete_performance_dashboard_template.gql";
import gql_delete_widget_template from "@/graphql/performance_metric/delete_widget.gql";
import gql_performance_agg from "@/graphql/performance_widget_design/agg_performance.gql";

import {
  getAggregatedAccountIds,
  prepareProductCapabilityData,
} from "@/composables/performanceDashboard";

const emptyFormData = {
  capability_id: null,
  config_override: null,
  description: null,
  label: null,
  layout: [],
  product_id: null,
  type: "user",
  warning_override: null,
  hide_if_empty: true,
  hide_if_aggregate: false,
  multi_field_missing_message: "",
  multi_field_missing_fields: [],
};
const emptyDashboardForm = {
  label: null,
  description: null,
  account_id: null,
  layout: [],
};

const defaultStoreState = () => ({
  currentStep: 1,
  showBuilderModal: false,
  performanceData: [],
  widgetBuilderType: "create",
  widgetDefinitions: [],
  productCapabilityData: {},
  products: [],
  widgetTemplates: [],
  dashboardTemplates: [],
  hiddenMetrics: [],
  formData: emptyFormData,
  dashboardFormData: emptyDashboardForm,
});

const state = defaultStoreState();

const getters = {
  widgetTemplatesWithData: (state, getters, rootState, rootGetters) => {
    const widgetTemplates = getters.widgetTemplates;
    const selectedTemplate = rootGetters["performance_dashboard/selectedTemplate"];
    const dashboardTemplates = rootGetters["performance_dashboard/performanceDashboardTemplates"];

    const allTemplates = [
      ...dashboardTemplates?.account_templates,
      ...dashboardTemplates?.user_templates,
      ...dashboardTemplates?.tenant_account_templates
  ];
  
    const template = allTemplates?.find(template => template?.id === selectedTemplate?.id);

    const productCapabilityData = getters.productCapabilityData;
    if (!widgetTemplates.length || !Object.values(productCapabilityData).length)
      return [];
    let newArr = [];
    let existingWidgets = {};
    widgetTemplates.sort((a, b) => (b.type == "tenant") - (a.type == "tenant"));
    widgetTemplates.forEach((item) => {
      if (!productCapabilityData?.[item.product_id]?.[item.capability_id])
        return;
      if (
        undefined !==
        existingWidgets[item.capability_id + "_" + item.product_id]
      ) {
        return;
      } else {
        if (
          item.type === "tenant" &&
          item.tenant_id !== rootGetters["tenant/userTenantId"]
        )
          return;
        existingWidgets[item.capability_id + "_" + item.product_id] =
          newArr.length;
        newArr.push({
          ...item,
          widgetData:
            productCapabilityData?.[item.product_id]?.[item.capability_id] ||
            {},
        });
      }
    });

    if (template) {
      newArr = newArr.filter(item => {
        return template.layout.some(layoutItem => layoutItem.widget_id === item.id);
      });
    
      newArr.sort((a, b) => {
        const orderA = template.layout?.find(item => item.widget_id === a.id)?.order || 0;
        const orderB = template.layout?.find(item => item.widget_id === b.id)?.order || 0;
        return orderA - orderB;
      });
    }
    return newArr;
  },
  dashboardFormData: (state, getters, rootState, rootGetters) => {
    return {
      ...state.dashboardFormData,
      account_id: state.dashboardFormData.account_id
        ? state.dashboardFormData.account_id
        : rootGetters["user/activeAccount"],
      // user_id: state.dashboardFormData.user_id
      //   ? state.dashboardFormData.user_id
      //   : rootGetters["user/userId"],
    };
  },
  productCapabilityData: (state) => state.productCapabilityData,
  aggAccountIds(state, getters, rootState, rootGetters) {
    const activeAccount = rootGetters["user/activeAccount"];
    const structure = rootGetters["structure/structure"];
    if (!activeAccount) return [];
    if (!structure?.length) return [activeAccount];
    const result = [
      ...new Set(getAggregatedAccountIds(structure, activeAccount)),
    ];
    if (!result.length) return [activeAccount];
    return result;
  },
  currentStep(state) {
    return state.currentStep;
  },
  selectedProduct: (state) => {
    return (
      state.products.find(
        (product) =>
          product.capability_id === state.formData.capability_id &&
          product.product_id === state.formData.product_id
      ) || null
    ); // return null if no matching product is found
  },
  computedMetrics: (state) => {
    const uniqueMetrics = {};
    const selectedCapability = state.formData.capability_id;
    const selectedProduct = state.formData.product_id;

    state.widgetDefinitions.forEach((item) => {
      if (selectedCapability && item.capability_id !== selectedCapability)
        return;
      if (selectedProduct && item.product_id !== selectedProduct) return;
      uniqueMetrics[item.id] = { ...item };
    });

    return Object.values(uniqueMetrics);
  },
  layout: (state) => state.formData.layout,
  widgetDefinitions: (state) => state.widgetDefinitions,
  widgetBuilderType: (state) => state.widgetBuilderType,
  formData: (state) => state.formData,
  widgetTemplates: (state) => {
    const widgetDefinitionMap = {};
    state.widgetDefinitions.forEach((widget) => {
      widgetDefinitionMap[widget.metric_id] = widget;
    });

    // Create a new layout array with the added widgetConfig
    return state.widgetTemplates.map((item) => {
      return {
        ...item,
        layout: item.layout.map((layoutItem) => {
          const correspondingWidget = widgetDefinitionMap[layoutItem.id];
          if (correspondingWidget) {
            return {
              ...layoutItem,
              widgetConfig: correspondingWidget,
            };
          }
          return layoutItem; // Return the original item if no match is found
        }),
      };
    });
  },
  hiddenMetrics: (state) => state.hiddenMetrics,
  showBuilderModal: (state) => state.showBuilderModal,
  products: (state) => state.products,
};

const actions = {
  updateStep: ({ commit }, step) => commit("updateStep", step),
  addItemToLayout({ commit, state }, { metric_id, item }) {
    const index = state.formData.layout.length;
    commit("ADD_ITEM_TO_LAYOUT", { metric_id, item, index });
    commit("TOGGLE_HIDDEN_METRIC", metric_id);
  },
  clearLayout({ commit }) {
    commit("CLEAR_LAYOUT");
  },
  async fetchProductCapabilityData({ commit, rootGetters, getters }) {
    let activeAccount = rootGetters["user/activeAccount"];
    if (!activeAccount) return;
    try {
      const { data } = await query({
        query: gql_performance_agg,
        variables: {
          account_id: getters.aggAccountIds?.length
          ? getters.aggAccountIds
          : [rootGetters["user/activeAccount"]]
        },
      });

      if (data?.performance_data) {
        commit("SET_PERFORMANCE_DATA", data.performance_data);
        commit(
          "SET_PRODUCT_CAPABILITY_DATA",
          prepareProductCapabilityData(
            data.performance_data,
            activeAccount
          )
        );
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async fetchWidgetDefinitions({ commit }) {
    try {
      const { data } = await query({
        query: gql_get_widget_definitions,
      });
      if (data?.performance_metric_template) {
        commit("SET_METRIC_DEFINITIONS", data.performance_metric_template);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async deleteWidgetTemplate({ commit }, id) {
    try {
      const { data } = await query({
        query: gql_delete_widget_template,
        variables: {
          id,
        },
      });
      if (data?.delete_widget_template_by_pk) {
        commit("DELETE_WIDGET_TEMPLATE", id);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchProducts({ commit }) {
    try {
      const { data } = await query({
        query: gql_get_products,
      });
      if (data?.performance_metric_template) {
        commit("SET_PRODUCTS", data.performance_metric_template);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchWidgetTemplates({ commit }) {
    try {
      const { data } = await query({
        query: gql_widget_template,
      });
      if (data?.widget_template) {
        commit("SET_WIDGET_TEMPLATES", data.widget_template);
      }
    } catch (error) {
      console.error(error);
    }
  },

  async updateWidgetTemplate({ commit }, payload) {
    try {
      const { data } = await query({
        query: gql_update_widget_template,
        variables: {
          id: payload.id,
          _set: payload._set,
        },
      });
      if (data?.update_widget_template_by_pk) {
        commit("UPDATE_WIDGET_TEMPLATE", data.update_widget_template_by_pk);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async submitWidgetTemplate({ commit }, payload) {
    try {
      const { data } = await query({
        query: gql_submit_widget_template,
        variables: {
          objects: payload,
        },
      });
      if (data?.insert_widget_template?.returning) {
        commit(
          "ADD_WIDGET_TEMPLATES",
          data.insert_widget_template.returning[0]
        );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async submitDashboardTemplate({ commit, rootGetters, dispatch }, { payload, defaultAccount, defaultTenant }) {
    const activeAccount = rootGetters["user/activeAccount"];
    const tenantId = rootGetters["tenant/userTenantId"]
    try {
      const { data } = await query({
        query: gql_insert_dashboard_template,
        variables: {
          objects: payload,
        },
      });
      if (data?.insert_performance_dashboard_template?.returning) {
        commit(
          "ADD_DASHBOARD_TEMPLATES",
          data.insert_performance_dashboard_template.returning[0]
        );
        dispatch("performance_dashboard/fetchDashboardTemplates", '', { root: true });
        dispatch("performance_dashboard/changeTemplate", data.insert_performance_dashboard_template.returning[0].id, { root: true });

        if(defaultAccount) {
          try {
            const { dataA } = await query({
              query: gql_update_account,
              variables: {
                id: activeAccount,
                _set: {"performance_dashboard_template_id": data.insert_performance_dashboard_template.returning[0].id }
              },
            });
            if (dataA) {
              console.log('success')
            }
          } catch (error) {
            console.log(error);
          }
        }
        if(defaultTenant) {
          try {
            const { dataT } = await query({
              query: gql_update_tenant,
              variables: {
                tenant_id: tenantId,
                _set: {"performance_dashboard_template_id": data.insert_performance_dashboard_template.returning[0].id }
              },
            });
            if (dataT) {
              console.log('success')
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  async deleteDashboardTemplate({ commit, dispatch }, id) {
    try {
      const { data } = await query({
        query: gql_delete_dashboard_template,
        variables: {
          dashboard_id: id
        },
      });
      if (data?.delete_performance_dashboard_template) {
        await dispatch("performance_dashboard/fetchDashboardTemplates", '', { root: true });
        await dispatch("performance_dashboard/changeTemplate", null, { root: true });
      }
    } catch (error) {
      console.error(error);
    }
  },
  removeItemFromLayout({ commit }, metric_id) {
    commit("REMOVE_ITEM_FROM_LAYOUT", metric_id);
  },
  changeWidgetBuilder({ commit }, type) {
    commit("CHANGE_WIDGET_BUILDER", type);
  },
  openBuilderModal({ commit }, { type, formData, layout }) {
    if (type === "update") {
      commit("updateFormData", formData);
      commit("SET_LAYOUT", layout);
    }
    commit("CHANGE_WIDGET_BUILDER", type);
    commit("TOGGLE_BUILDER_MODAL");
  },
  toggleBuilderModal({ commit }) {
    commit("TOGGLE_BUILDER_MODAL");
  },
  updateFormData: ({ commit }, data) => commit("updateFormData", data),
  updateDashboardFormData: ({ commit }, data) =>
    commit("updateDashboardFormData", data),
  toggleHiddenMetric({ commit }, uuid) {
    commit("TOGGLE_HIDDEN_METRIC", uuid);
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_PERFORMANCE_DATA(state, content) {
    state.performanceData = content;
  },
  SET_PRODUCT_CAPABILITY_DATA(state, content) {
    state.productCapabilityData = content;
  },
  UPDATE_WIDGET_TEMPLATE(state, updatedTemplate) {
    // Find the index of the widgetTemplate with the matching id
    const index = state.widgetTemplates.findIndex(
      (template) => template.id === updatedTemplate.id
    );

    // If a matching widgetTemplate is found, update its fields
    if (index !== -1) {
      state.widgetTemplates[index] = {
        ...state.widgetTemplates[index],
        ...updatedTemplate,
      };
    }
  },
  updateStep(state, step) {
    state.currentStep = step;
  },
  CLEAR_LAYOUT(state) {
    state.hiddenMetrics = [];
    state.formData.layout = [];
  },
  SET_METRIC_DEFINITIONS(state, content) {
    state.widgetDefinitions = content;
  },
  CHANGE_WIDGET_BUILDER(state, type) {
    state.widgetBuilderType = type;
  },
  TOGGLE_BUILDER_MODAL(state) {
    if (state.showBuilderModal) {
      state.formData = emptyFormData;
      state.currentStep = 1;
    }
    state.showBuilderModal = !state.showBuilderModal;
  },
  SET_LAYOUT(state, layout) {
    state.formData.layout = layout;
  },
  SET_PRODUCTS(state, content) {
    state.products = content;
  },
  SET_WIDGET_TEMPLATES(state, content) {
    state.widgetTemplates = content;
  },
  ADD_WIDGET_TEMPLATES(state, content) {
    state.widgetTemplates.push(content);
  },
  ADD_DASHBOARD_TEMPLATES(state, content) {
    state.dashboardTemplates.push(content);
  },
  TOGGLE_HIDDEN_METRIC(state, uuid) {
    const index = state.hiddenMetrics.indexOf(uuid);
    if (index === -1) {
      state.hiddenMetrics.push(uuid);
    } else {
      state.hiddenMetrics.splice(index, 1);
    }
  },
  REMOVE_ITEM_FROM_LAYOUT(state, metric_id) {
    const index = state.formData.layout.findIndex(
      (item) => item.id === metric_id
    );
    if (index !== -1) {
      state.formData.layout.splice(index, 1);
    }
  },
  DELETE_WIDGET_TEMPLATE(state, id) {
    const index = state.widgetTemplates.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.widgetTemplates.splice(index, 1);
    }
  },
  ADD_ITEM_TO_LAYOUT(state, { metric_id, item, index }) {
    const newItem = {
      x: (state.formData.layout.length * 4) % 12,
      y: state.formData.layout.length + 12,
      w: 4,
      h: 2,
      i: index,
      id: metric_id,
      widgetConfig: item,
    };
    state.formData.layout.push(newItem);
  },
  updateFormData(state, data) {
    state.formData = {
      ...state.formData,
      ...data,
      layout: state.formData.layout,
    };
  },
  updateDashboardFormData(state, data) {
    state.dashboardFormData = {
      ...state.dashboardFormData,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
