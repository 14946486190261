<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    localStorage.setItem("azure_stuff", this.$route.query);
  },
};
</script>
