<template>
  <AuthAnim>
    <div class="flex w-full flex-col items-center">
      <div class="w-3/4 rounded-lg border border-gray-700 bg-arco-dark-card/70 md:w-3/4 lg:w-2/4 xl:w-2/6">
        <div class="h-2 border-b border-gray-700/50" :style="{ backgroundImage: `linear-gradient(to right, transparent, ${VUE_APP_PRIMARY_COLOR}, transparent)` }"></div>
        <div class="flex w-full flex-row">
          <div class="h-auto w-1/2 bg-arco-dark-card/10 bg-center bg-no-repeat p-5 pt-0 lg:block lg:w-1/2" style="background-size: 30%" :style="{ backgroundImage: `url('${VUE_APP_LOGO}')` }"></div>
          <div class="w-1/2 rounded-lg bg-arco-dark-card/10 p-10">Logging out...</div>
        </div>

        <div class="h-2 border-t border-gray-700/50" :style="{ backgroundImage: `linear-gradient(to right, transparent, ${VUE_APP_PRIMARY_COLOR}, transparent)` }"></div>
      </div>
    </div>
  </AuthAnim>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import AuthAnim from "@/views/Auth/AuthAnim.vue";

export default {
  components: { AuthAnim },
  data: () => ({
    email: "",
    password: "",
    logo_login: import.meta.env.VUE_APP_LOGO_LOGIN || "https://cdn-uk1.redsmart.app/arco/environments/b8c1cf27-5e2e-41ed-a67c-26783b169322/arco_logo.svg",
    loading: false,
    forgot: false,
    error: null,
    primary: import.meta.env.VUE_APP_PRIMARY_COLOR || "#43c6fd",
  }),
  mounted() {
    localStorage.removeItem("jwt_expires");
    localStorage.removeItem("jwt_token");
    this.$store.commit("account/RESET_STORE");
    this.logout();
    this.$router.push({ name: "Login" });
  },
  methods: {
    async logout() {
      await axios.post(`${this.$authEndpoint}/logout`, { withCredentials: true });
    },
  },
  computed: {
    ...mapGetters("ui", ["VUE_APP_PRIMARY_COLOR", "VUE_APP_PRIMARY_HIGHLIGHT_COLOR", "VUE_APP_SECONDARY_COLOR", "VUE_APP_LOGO", "VUE_APP_LOGO_LOGIN", "VUE_APP_LOGO_DARK", "VUE_APP_DOC_TEMPLATE"]),
  },
};
</script>
