import { createStore } from "vuex";
import loading from "./modules/loading";
import user from "./modules/user";
import efficiency from "./modules/efficiency";
import ui from "./modules/ui";
import filters from "./modules/filters";
import flows from "./modules/flows";
import account from "./modules/account";
import tenant from "./modules/tenant";
import insights from "./modules/insights";
import threat from "./modules/threat";
import findings from "./modules/findings";
import metadata from "./modules/metadata";
import project from "./modules/project";
import onboarding from "./modules/onboarding";
import ti from "./modules/ti";
import compliance_baseline from "./modules/compliance_baseline";
import structure from "./modules/structure";
import heatmap from "./modules/heatmap";
import notification from "./modules/notification";
import risk from "./modules/risk";
import analytics from "./modules/analytics";
import performance_dashboard from "./modules/performance_dashboard";
import performance_metric from "./modules/performance_metric";
import performance_icon from "./modules/performance_icon";
import performance_modal from "./modules/performance_modal";

import navigation from "./modules/navigation";
import administration from "./modules/administration";
//performance

export default createStore({
  modules: {
    loading,
    account,
    efficiency,
    findings,
    filters,
    heatmap,
    insights,
    metadata,
    project,
    ti,
    threat,
    tenant,
    ui,
    user,
    onboarding,
    navigation,
    compliance_baseline,
    performance_dashboard,
    performance_metric,
    structure,
    notification,
    risk,
    analytics,
    administration,
    performance_modal,
    performance_icon,
    flows,
  },
});
