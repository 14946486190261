import { query } from "@/api";
import gql_performance_agg from "@/graphql/performance_widget_design/agg_performance.gql";
import gql_account_global_metric from "@/graphql/account/account_global_metric.gql";
import gql_get_performance_dashboard_template from "@/graphql/performance_dashboard_template/get_performance_dashboard_template.gql";
import gql_account_global_metric_asset_count_aggregate from "@/graphql/account/account_global_metric_asset_count_aggregate.gql";
import control_risks_threat from "@/graphql/performance/get_performance_control_risks.gql";
import {
  getAggregatedAccountIds,
  prepareProductCapabilityData,
} from "@/composables/performanceDashboard";

const defaultStoreState = () => ({
  dataMode: "one",
  globalMetrics: {},
  globalAssetCount: null,
  product_capability_data: [],
  performanceDashboardTemplates: {
    account_templates: [],
    user_templates: [],
    tenant_account_templates: [],
  },
  selectedTemplate: null,
  widget_design_data: [],
  threatScore: null,
  sector: null,
  country: null,
  loadingNewAccount: true,
});

const state = defaultStoreState();

const getters = {
  dataMode: (state) => state.dataMode,
  globalMetrics: (state) => state.globalMetrics,
  globalAssetCount: (state) => state.globalAssetCount,
  product_capability_data: (state) => state.product_capability_data,
  performanceDashboardTemplates: (state) => state.performanceDashboardTemplates,
  widget_design_data: (state) => state.widget_design_data,
  threatScore: (state) => state.threatScore,
  sector: (state) => state.sector,
  templateOptions: (state) => {
    const allTemplates = [
      ...state.performanceDashboardTemplates.account_templates,
      ...state.performanceDashboardTemplates.user_templates,
      ...state.performanceDashboardTemplates.tenant_account_templates,
    ];
    const uniqueTemplates = new Set();
    const mappedTemplates = allTemplates
      .filter((template) => template && template.label && template.id)
      .filter((template) => {
        const isUnique =
          !uniqueTemplates.has(template.label) &&
          !uniqueTemplates.has(template.id);
        if (isUnique) {
          uniqueTemplates.add(template.label);
          uniqueTemplates.add(template.id);
        }
        return isUnique;
      })
      .map((template) => ({ label: template.label, id: template.id }));

    mappedTemplates.push({ label: "Performance Dashboard", id: null });
    return mappedTemplates;
  },
  selectedTemplate(state, getters, rootState, rootGetters) {
    return getters.templateOptions.find((f) => f.id === state.selectedTemplate);
  },
  selectedTemplateDetail(state, getters, rootState, rootGetters) {
    const currentAccountDetail = rootGetters["account/currentAccountDetail"];
    const accountTemplate =
      currentAccountDetail?.performance_dashboard_template;
    const tenantTemplate =
      currentAccountDetail?.tenant_account?.[0]?.tenant
        ?.performance_dashboard_template;
    return accountTemplate || tenantTemplate || null;
  },
  country: (state) => state.country,
  loadingNewAccount: (state) => state.loadingNewAccount,
};

const actions = {
  changeDataMode({ commit }, payload) {
    commit("SET_DATA_MODE", payload);
  },
  navigateTemplates({ commit, state, getters }, direction) {
    let currentIndex = getters.templateOptions.findIndex(
      (template) => template.id === state.selectedTemplate
    );
    if (currentIndex === -1) {
      // If current selectedTemplate id is not found, default to the first item if direction is forward
      // and to the last item if direction is backward
      currentIndex =
        direction === "forward" ? -1 : getters.templateOptions.length;
    }

    // Calculate the new index based on the direction
    let newIndex =
      direction === "forward" ? currentIndex + 1 : currentIndex - 1;

    // Wrap around if necessary
    if (newIndex >= getters.templateOptions.length) {
      newIndex = 0; // Go to the first item
    } else if (newIndex < 0) {
      newIndex = getters.templateOptions.length - 1; // Go to the last item
    }

    // Get the new template
    const newTemplate = getters.templateOptions[newIndex];

    // Commit the new selected template id to the state
    commit("setSelectedTemplate", newTemplate.id);
  },
  changeTemplate({ commit, state, getters }, newTemplate) {
    commit("setSelectedTemplate", newTemplate);
  },
  async fetchThreat({ commit }, accountName) {
    try {
      const { data } = await query({
        query: control_risks_threat,
      });
      if (data?.performance_control_risks) {
        const matchedData = data.performance_control_risks.find(
          (f) => f.vbu_name === accountName
        );
        if (matchedData) {
          commit("SET_THREAT_SCORE", matchedData.threat_score);
          commit("SET_SECTOR", matchedData.sector);
          commit("SET_COUNTRY", matchedData.country);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchGlobalMetrics({ commit, rootGetters }) {
    try {
      const { data } = await query({
        query: gql_account_global_metric,
        variables: {
          account_id:
            rootGetters["account/currentAccount"] ||
            "00000000-0000-0000-0000-000000000000",
        },
      });
      if (data?.account_global_metric_data) {
        let result = {
          last_updated: "",
        };

        data.account_global_metric_data.forEach((item) => {
          // Assuming 'global_metric' holds the key for the metric you want to grab
          if (item.global_metric && item.custom_data_for_path) {
            result[item.global_metric] = item.custom_data_for_path;
          }

          // Check if 'timestamp' is more recent than what we have stored in 'result'
          if (
            !result.last_updated ||
            new Date(item.timestamp) > new Date(result.last_updated)
          ) {
            result.last_updated = item.timestamp;
          }
        });
        commit("SET_GLOBAL_METRICS", result);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchAssetCounts({ commit, rootGetters }) {
    const currentAccount = rootGetters["account/currentAccount"];
    const struData = rootGetters["structure/structure"];
    if (!currentAccount || !struData?.length) return;
    const aggAccountIds = [
      ...new Set(getAggregatedAccountIds(struData, currentAccount)),
    ];
    try {
      const { data } = await query({
        query: gql_account_global_metric_asset_count_aggregate,
        variables: {
          account_id:
            rootGetters["account/currentAccount"] ||
            "00000000-0000-0000-0000-000000000000",
          account_ids: aggAccountIds,
        },
      });
      if (data?.asset_count_aggregate || data?.asset_count) {
        commit("SET_GLOBAL_ASSET_COUNT", {
          asset_count_aggregate:
            data.asset_count_aggregate.aggregate.sum.asset_count_aggregate,
          asset_count: data.asset_count.aggregate.sum.asset_count,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchDashboardTemplates({ commit, rootGetters }) {
    try {
      const user_id =
        rootGetters["user/userId"] || "00000000-0000-0000-0000-000000000000";
      const account_id =
        rootGetters["account/currentAccount"] ||
        "00000000-0000-0000-0000-000000000000";
      const account_tenant_id = rootGetters[
        "account/currentAccountDetail"
      ]?.tenant_account?.map((d) => d.tenant_id) || [
        "00000000-0000-0000-0000-000000000000",
      ];
      const { data } = await query({
        query: gql_get_performance_dashboard_template,
        variables: {
          user_id,
          account_id,
          tenant_id: account_tenant_id,
        },
      });
      if (data) {
        const { account_templates, user_templates, tenant_account_templates } =
          data;

        const filtered_tenant_account_templates = tenant_account_templates.filter(
          (template) =>
            !account_templates.some(
              (acc_template) => acc_template.id === template.id
            ) &&
            !user_templates.some(
              (usr_template) => usr_template.id === template.id
            )
        );

        commit("SET_DASHBOARD_TEMPLATES", {
          type: "account_templates",
          data: account_templates,
        });
        commit("SET_DASHBOARD_TEMPLATES", {
          type: "user_templates",
          data: user_templates,
        });
        commit("SET_DASHBOARD_TEMPLATES", {
          type: "tenant_account_templates",
          data: filtered_tenant_account_templates,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchPerformanceData({ commit, state, rootGetters }) {
    const currentAccount = rootGetters["account/currentAccount"];
    const struData = rootGetters["structure/structure"];
    if (!currentAccount || !struData?.length) return;
    const aggAccountIds = [
      ...new Set(getAggregatedAccountIds(struData, currentAccount)),
    ];
    try {
      const { data } = await query({
        query: gql_performance_agg,
        variables: {
          account_id: aggAccountIds,
        },
      });
      if (data?.performance_data) {
        const preparedData = prepareProductCapabilityData(
          data.performance_data,
          currentAccount
        );
        commit("SET_PRODUCT_CAPABILITY_DATA", preparedData);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  setSelectedTemplate(state, newTemplateId) {
    state.selectedTemplate = newTemplateId;
  },
  SET_GLOBAL_METRICS(state, data) {
    state.globalMetrics = data;
  },
  SET_DATA_MODE(state, content) {
    state.dataMode = content;
  },
  SET_PRODUCT_CAPABILITY_DATA(state, data) {
    state.product_capability_data = data;
  },
  SET_WIDGET_DESIGN_DATA(state, data) {
    state.widget_design_data = data;
  },
  SET_DASHBOARD_TEMPLATES(state, { type, data }) {
    state.performanceDashboardTemplates[type] = data;
  },
  SET_GLOBAL_ASSET_COUNT(state, data) {
    state.globalAssetCount = data;
  },
  SET_THREAT_DATA(state, { threatScore, sector, country }) {
    state.threatScore = threatScore;
    state.sector = sector;
    state.country = country;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
