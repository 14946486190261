const state = {
  currentTabs: [],
};

const getters = {
  currentTabs: (state) => state.currentTabs,
};

const mutations = {
  setTabs(state, tabs) {
    console.log(tabs);
    state.currentTabs = tabs;
  },
  clearTabs(state) {
    state.currentTabs = [];
  },
};
const actions = {
  setTabs({ commit }, tabs) {
    commit("setTabs", tabs);
  },
  clearTabs({ commit }) {
    commit("clearTabs");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
