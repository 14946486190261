export const default_capabilities = [
  { id: 3, order: 0, name: "Endpoint Security" },
  { id: 80, order: 1, name: "Web Application Scans" },
  { id: 21, order: 2, name: "Email Security" },
  { id: 32, order: 3, name: "Security Awareness/Training" },
  { id: 10, order: 4, name: "Digital Risk Management" },
  { id: 47, order: 5, name: "Web Security Gateway" },
  { id: 15, order: 6, name: "Vulnerability Management" },
  { id: 36, order: 7, name: "Multi-Factor Authentication" },
  { id: 79, order: 8, name: "HR Systems" },
  { id: 27, order: 9, name: "GRC & Risk Management" },
  { id: 70, order: 10, name: "Third Party Risk Management" },
];

export const heatmapDemo = [
  {
    org_name: "CreditUnion",
    org_type: "VBU",
    score: "96.4",
    order: 1,
    rank: 1,
    data: [
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 93.4,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 9,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 8,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 9,
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 60229,
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 95.9,
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 52,
        tool: "6clicks",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "complete",
        tool: "6clicks",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "A",
        tool: "Security Scorecard",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Security Scorecard",
      },
    ],
  },
  {
    org_name: "Eureka",
    org_type: "VBU",
    score: "78.2",
    order: 2,
    rank: 3,
    data: [
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Crowdstrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 95.9,
        tool: "Crowdstrike",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 7,
        tool: "Invicti",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 92,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 7,
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "Rapid7",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 42,
        tool: "6clicks",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "complete",
        tool: "6clicks",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: null,
        tool: "Security Scorecard",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: null,
        tool: "Security Scorecard",
      },
    ],
  },
  {
    org_name: "Rhapsody Networks",
    org_type: "VBU",
    score: "80.9",
    order: 3,
    rank: 2,
    data: [
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 93.4,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 27,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 31,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 1,
        tool: "proofpoint",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 95,
        tool: "Rapid7",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 11,
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 53,
        tool: "6clicks",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: "in prog",
        tool: "6clicks",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "A",
        tool: "Security Scorecard",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Security Scorecard",
      },
    ],
  },
  {
    org_name: "Exocare",
    org_type: "VBU",
    score: "72.0",
    order: 4,
    rank: 4,
    data: [
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 99.3,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Invicti",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 149,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: "active*",
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "Rapid7",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Workday",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "exempt",
        tool: "6clicks",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: "exempt",
        tool: "6clicks",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: "C",
        tool: "Security Scorecard",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Security Scorecard",
      },
    ],
  },
  {
    org_name: "Shoplocal",
    org_type: "VBU",
    score: "64.5",
    order: 5,
    rank: 5,
    data: [
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 3,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 94.4,
        tool: "CrowdStrike",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 35,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 18,
        tool: "Invicti",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "proofpoint",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "75, 84, 98", blind: "75, 84, 98" },
        value: "!",
        tool: "KnowBe4",
      },
      {
        color: { normal: "253, 200, 48", blind: "109, 182, 255" },
        value: 1,
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "SpyCloud",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 0,
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Cisco Umbrella",
      },
      {
        color: { normal: "243, 115, 53", blind: "182, 109, 255" },
        value: 4596,
        tool: "Rapid7",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 6.2,
        tool: "Rapid7",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: 100,
        tool: "Azure",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Azure",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 65,
        tool: "Workday",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 1,
        tool: "Workday",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: 6,
        tool: "6clicks",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "complete",
        tool: "6clicks",
      },
      {
        color: { normal: "203, 45, 62", blind: "73, 0, 146" },
        value: "F",
        tool: "Security Scorecard",
      },
      {
        color: { normal: "56, 239, 125", blind: "182, 219, 255" },
        value: "active",
        tool: "Security Scorecard",
      },
    ],
  },
];
