const defaultStoreState = () => ({
  isDrawerOpen: false,
  openHelpModal: false,
  colorblindMode: false,
  themeVariables: {
    VUE_APP_PRIMARY_COLOR: import.meta.env.VITE_APP_PRIMARY_COLOR || "#27248e",
    VUE_APP_PRIMARY_HIGHLIGHT_COLOR:
      import.meta.env.VITE_APP_PRIMARY_HIGHLIGHT_COLOR || "#416be2",
    VUE_APP_SECONDARY_COLOR:
      import.meta.env.VITE_APP_SECONDARY_COLOR || "#FC2128",
    VUE_APP_LOGO:
      import.meta.env.VITE_APP_LOGO_DARKMODE ||
      "https://cdn-uk1.redsmart.app/arco/environments/b8c1cf27-5e2e-41ed-a67c-26783b169322/arco_logo_dark.svg",
    VUE_APP_LOGO_LOGIN:
      import.meta.env.VITE_APP_LOGO_LOGIN ||
      "https://cdn-uk1.redsmart.app/arco/environments/b8c1cf27-5e2e-41ed-a67c-26783b169322/arco_logo.svg",
    VUE_APP_DOC_TEMPLATE:
      import.meta.env.VITE_APP_DOC_TEMPLATE ||
      "https://arco-uk.fra1.digitaloceanspaces.com/softcat_template.docx",
    VUE_APP_TITLE: import.meta.env.VITE_APP_TITLE || "Arco Cyber",
    VUE_APP_FAVICON:
      import.meta.env.VITE_APP_FAVICON ||
      "https://cdn-uk1.redsmart.app/arco/environments/b8c1cf27-5e2e-41ed-a67c-26783b169322/favicon.ico",
    VUE_APP_TABLE_IMG: import.meta.env.VITE_APP_TABLE_IMG,
    tenant_id: null,
    custom_font: null,
    kb_url: import.meta.env.VITE_APP_SUPPORT_PORTAL || null,
    welcome_email_contributor:
      import.meta.env.VITE_APP_WELCOME_EMAIL_CONTRIBUTOR || null,
    welcome_email_other: import.meta.env.VITE_APP_WELCOME_EMAIL_OTHER || null,
  },
});

const state = defaultStoreState();

const getters = {
  openHelpModal: (state) => state.openHelpModal,
  isDrawerOpen: (state) => state.isDrawerOpen,
  VUE_APP_PRIMARY_COLOR: (state) => state.themeVariables.VUE_APP_PRIMARY_COLOR,
  VUE_APP_PRIMARY_HIGHLIGHT_COLOR: (state) =>
    state.themeVariables.VUE_APP_PRIMARY_HIGHLIGHT_COLOR,
  VUE_APP_SECONDARY_COLOR: (state) =>
    state.themeVariables.VUE_APP_SECONDARY_COLOR,
  VUE_APP_LOGO: (state) => state.themeVariables.VUE_APP_LOGO,
  VUE_APP_LOGO_LOGIN: (state) => state.themeVariables.VUE_APP_LOGO_LOGIN,
  VUE_APP_DOC_TEMPLATE: (state) => state.themeVariables.VUE_APP_DOC_TEMPLATE,
  VUE_APP_TITLE: (state) => state.themeVariables.VUE_APP_TITLE,
  VUE_APP_FAVICON: (state) => state.themeVariables.VUE_APP_FAVICON,
  VUE_APP_GAUGE_IMG: (state) => state.themeVariables.VUE_APP_GAUGE_IMG,
  VUE_APP_CHART_IMG: (state) => state.themeVariables.VUE_APP_CHART_IMG,
  VUE_APP_TABLE_IMG: (state) => state.themeVariables.VUE_APP_TABLE_IMG,
  welcome_email_contributor: (state) =>
    state.themeVariables.welcome_email_contributor,
  welcome_email_other: (state) => state.themeVariables.welcome_email_other,
  tenant_id: (state) => state.themeVariables.tenant_id,
  custom_font: (state) => state.themeVariables.custom_font,
  kb_url: (state) => state.themeVariables.kb_url,
};

const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  TOGGLE_HELP_MODAL: (state) => {
    state.openHelpModal = !state.openHelpModal;
  },
  SET_THEME: (state, content) => {
    state.themeVariables = {
      ...state.themeVariables,
      ...content,
    };
  },
  SET_TITLE: (state, content) => {
    //window.document.title = content;
  },
  // SET_FAVICON: (state, content) => {
  //   const favicon = document.getElementById("favicon");
  //   favicon.href = content;
  // },
  SET_DRAWER_STATE(state, isOpen) {
    state.isDrawerOpen = isOpen;
  },
};

const actions = {
  toggleDrawer({ commit, state }) {
    commit("SET_DRAWER_STATE", !state.isDrawerOpen);
  },
  openDrawer({ commit }) {
    commit("SET_DRAWER_STATE", true);
  },
  closeDrawer({ commit }) {
    commit("SET_DRAWER_STATE", false);
  },
  // changeFavicon({ commit }, content) {
  //   commit("SET_FAVICON", content);
  // },
  changeTitle({ commit }, content) {
    commit("SET_TITLE", content);
  },
  changeTheme({ commit }, content) {
    commit("SET_THEME", content);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
