export function extractScoreFromSection(section) {
  const newObj = {
    id: section.id,
    name: section.name,
    some: 0,
    yes: 0,
    not_applicable: 0,
    no: 0,
    unanswered: 0,
    follow_up_req: 0,
    unmapped: 0,
    total_possible: null,
    answer_sum: null,
    total_questions: null,
    avg_pct: section.avg_pct,
    total_pct: null,
  };
  newObj.question_total = section.questions.length;
  section.questions.forEach((question) => {
    question.answers.forEach((a) => {
      if (!a.deployment_status_text) return (newObj.unanswered += 1);
      if (a.deployment_status_text === "N/A")
        return (newObj.not_applicable += 1);
      if (a.deployment_status_text === "Follow-up Required")
        return (newObj.follow_up_req += 1);
      if (a.deployment_status_text === "Yes") {
        newObj.yes += 1;
      } else if (a.deployment_status_text === "Some") {
        newObj.some += 1;
      } else if (a.deployment_status_text === "No") {
        newObj.no += 1;
      } else {
        newObj.unmapped += 1;
      }
    });
  });
  newObj.answer_sum = newObj.some * 50 + newObj.yes * 100;
  newObj.total_possible =
    (section.questions.length - newObj.not_applicable) * 100;
  newObj.total_questions = section.questions.length;
  newObj.total_pct = Math.round(
    (newObj.answer_sum / newObj.total_possible) * 100
  );

  return newObj;
}

export function extractMaturityFromSection(section) {
  let answerArr = [];

  const maturityLevels = {
    Initial: 1,
    Repeatable: 2,
    Defined: 3,
    Managed: 4,
    Optimizing: 5,
  };

  section.questions.forEach((question) => {
    question.answers.forEach((a) => {
      if (!a.deployment_status_text || a.deployment_status_text === "N/A")
        return;
      const score = !isNaN(a.maturity_level)
        ? Number(a.maturity_level)
        : maturityLevels[a.maturity_level]
        ? maturityLevels[a.maturity_level]
        : 0;
      if (a.deployment_status_text === "Yes") {
        return answerArr.push(score);
      } else if (a.deployment_status_text === "Some") {
        return answerArr.push(0.5);
      } else if (a.deployment_status_text === "No") {
        return answerArr.push(0);
      } else {
        return answerArr.push(0);
      }
    });
  });

  if (answerArr.length === 0) {
    return 0;
  }

  return Number(
    (answerArr.reduce((a, b) => a + b, 0) / answerArr.length).toFixed(2)
  );
}

export function extractMaturityFromQuestion(question) {
  let answerArr = [];

  const maturityLevels = {
    Initial: 1,
    Repeatable: 2,
    Defined: 3,
    Managed: 4,
    Optimizing: 5,
  };

  question.answers.forEach((a) => {
    if (!a.deployment_status_text || a.deployment_status_text === "N/A") return;
    const score = !isNaN(a.maturity_level)
      ? Number(a.maturity_level)
      : maturityLevels[a.maturity_level]
      ? maturityLevels[a.maturity_level]
      : 0;
    if (a.deployment_status_text === "Yes") {
      return answerArr.push(score);
    } else if (a.deployment_status_text === "Some") {
      return answerArr.push(0.5);
    } else if (a.deployment_status_text === "No") {
      return answerArr.push(0);
    } else {
      return answerArr.push(0);
    }
  });

  if (answerArr.length === 0) {
    return 0;
  }

  return Number(
    (answerArr.reduce((a, b) => a + b, 0) / answerArr.length).toFixed(2)
  );
}
