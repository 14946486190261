import getTenant from "@/graphql/tenant/get_tenant.gql";
import getActiveBrand from "@/graphql/tenant/get_active_brand.gql";
import getTenantSubscription from "@/graphql/tenant/get_tenant_subscription.gql";
import getTenantCustomSubscription from "@/graphql/tenant/get_custom_subscription.gql";
import getFlows from "@/graphql/flows/get_flows.gql";
import { query } from "@/api";

const defaultStoreState = () => ({
  tenantLoaded: false,
  user_tenant_detail: null,
  subscription: null,
  flows: null,
});

const state = defaultStoreState();

const getters = {
  flows: (state) => state.flows,
  isFlowsEnabled: (state) => state.user_tenant_detail.flows_enabled ?? false,
  tenantLoaded: (state) => state.tenantLoaded,
  isMssp: (state, getters) => getters.userTenantDetail?.is_mssp ?? false,
  isBasic: (state, getters) =>
    getters.userTenantDetail?.subscription.name === "arco_basic",
  subscription: (state) => state.subscription,
  userTenantDetail: (state) => state.user_tenant_detail,
  userTenantId: (state) => state.user_tenant_detail?.id || null,
  modules: (state) => {
    if (!state.user_tenant_detail?.subscription?.modules) return null;
    return state.user_tenant_detail?.subscription?.modules;
  },
  features: (state) => {
    if (!state.user_tenant_detail?.subscription?.features) return null;
    return state.user_tenant_detail?.subscription?.features;
  },
  tenantFrameworkConfiguration(state, getters) {
    return getters.userTenantDetail?.framework_configuration || null;
  },
};

const actions = {
  async getActiveTenantBrand({ dispatch }, tenant_id) {
    if (import.meta.env.VITE_FIXED_BRANDING === "true") {
      return;
    }

    const {
      data: { tenant_brand_by_pk },
    } = await query({
      query: getActiveBrand,
      variables: {
        tenant_id,
      },
    });

    dispatch("ui/changeTheme", tenant_brand_by_pk, { root: true });

    // if (tenant_brand_by_pk?.VUE_APP_FAVICON) {
    //   dispatch("ui/changeFavicon", tenant_brand_by_pk.VUE_APP_FAVICON, {
    //     root: true,
    //   });
    // }

    if (tenant_brand_by_pk?.VUE_APP_TITLE) {
      dispatch("ui/changeTitle", tenant_brand_by_pk.VUE_APP_TITLE, {
        root: true,
      });
    }
  },
  async getTenantData({ commit, dispatch }) {
    const { data } = await query({
      query: getTenant,
    });
    if (!data) {
      commit("SET_TENANT_LOADED");
      return;
    }
    if (data.tenant_for_user && data.tenant_for_user.length > 0) {
      commit("SET_TENANT_DATA", data.tenant_for_user[0]);
    }
    commit("SET_TENANT_LOADED");
  },
  async getFlowData({ commit, state }) {
    if (!state.subscription || !state.subscription.modules) {
      throw new Error("Subscription data is not loaded yet.");
    }
    try {
      const modules = state.subscription.modules.map((module) => module.id);
      const { data } = await query({
        query: getFlows,
        variables: {
          modules,
        },
      });
      commit("setFlows", data.flows);
    } catch (error) {
      console.error("Error fetching flows.", error);
    }
  },
  async getSubscriptionData({ commit, dispatch }, payload) {
    const data = await query({
      query: getTenantSubscription,
      variables: {
        subscription_id: payload.subscription_id,
        role: payload.role,
        tenant_id: payload.tenant_id,
      },
    });
    commit(
      "SET_SUBSCRIPTION_DATA",

      data.data.locked_modules_features[0]
    );
    await dispatch("getFlowData");
  },
  async getCustomSubscriptionData({ commit, dispatch }, { tenant_id, role }) {
    const data = await query({
      query: getTenantCustomSubscription,
      variables: { tenant_id: tenant_id, role: role },
    });
    commit(
      "SET_SUBSCRIPTION_DATA",

      data.data.tenant_modules_features[0]
    );
    await dispatch("getFlowData");
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_TENANT_LOADED(state) {
    state.tenantLoaded = true;
  },
  SET_SUBSCRIPTION_DATA(state, data) {
    state.subscription = data;
  },
  setFlows(state, flows) {
    state.flows = flows;
  },
  SET_TENANT_DATA(state, content) {
    state.user_tenant_detail = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
