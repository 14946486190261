import gql_orpheus_threat_profile from "@/graphql/ti/orpheus_threat_profile.gql";
import gql_controlrisks_tactics_variety from "@/graphql/ti/controlrisks_tactics_variety.gql";
import gql_controlrisks_tactics_volume from "@/graphql/ti/controlrisks_tactics_volume.gql";
import gql_controlrisks_threat_profile from "@/graphql/ti/controlrisks_threat_profile.gql";
import chroma from "chroma-js";
import { query } from "@/api";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const defaultStoreState = () => ({
  controlrisks_profile: null,
  controlrisks_variety: null,
  controlrisks_volume: null,
});

const state = defaultStoreState();

const getters = {
  orpheus: (state, getters, rootState, rootGetters) =>
    rootGetters["account/currentAccountDetail"]?.orpheus_threat_profile
      ? rootGetters["account/currentAccountDetail"].orpheus_threat_profile
      : null,
  orpheusTTPs: (state, getters) => {
    const threat_profiles = getters.orpheus?.threat_profile || null;
    if (!threat_profiles) return null;
    let newArr = [];
    let cleanArr = [];
    threat_profiles.forEach((t) => {
      newArr.push(t);
    });
    newArr.forEach((d) => {
      const check = cleanArr.findIndex(
        (f) => f.technique_id === d.technique_id
      );
      if (check === -1) {
        cleanArr.push({
          ...d,
          count: 1,
        });
      } else {
        return (cleanArr[check].count = cleanArr[check].count + 1);
      }
    });

    return cleanArr.sort((a, b) => b.count - a.count);
  },

  orpheusTacticsGradient: (state, getters) => {
    if (!getters.accountTactics) return null;
    return chroma
      .scale(["#263239", "#cb2d3e"])
      .mode("lab")
      .colors(getters.accountTactics.highest);
  },
  orpheusTechniques: (state, getters) => {
    if (!getters.accountTactics) return null;
    const map = getters.accountTactics.map;
    let newArr = [];
    Object.entries(map).forEach((d) => {
      Object.entries(d[1]).forEach((k) => {
        newArr.push(k[1]);
      });
    });
    return newArr.sort((a, b) => b.count - a.count).slice(0, 10);
  },
  accountTechniquesAll: (state, getters) => {
    if (!getters.accountTactics) return null;
    const map = getters.accountTactics.map;
    let newArr = [];
    Object.entries(map).forEach((d) => {
      Object.entries(d[1]).forEach((k) => {
        newArr.push(k[1]);
      });
    });
    return newArr.sort((a, b) => b.count - a.count);
  },
  orpheusAttackVariety: (state, getters) => {
    if (!getters.orpheusTTPs) return null;
    let newObj = {};
    getters.orpheusTTPs.forEach((d) => {
      if (d.technique?.kill_chain_phases) {
        d.technique.kill_chain_phases.forEach((k) => {
          const tactic = toTitleCase(k.phase_name.replace(/-/, " "));
          if (newObj[tactic]) {
            newObj[tactic]++;
          } else {
            newObj[tactic] = 1;
          }
        });
      }
    });
    return newObj;
  },
  orpheusVolumeAttackCategory: (state, getters) => {
    if (!getters.orpheus || !getters.orpheus.threat_profile) return null;
    let newObj = {};

    getters.orpheus.threat_profile.forEach((d) => {
      if (d.technique?.kill_chain_phases) {
        d.technique.kill_chain_phases.forEach((k) => {
          const tactic = toTitleCase(k.phase_name.replace(/-/, " "));
          if (newObj[tactic]) {
            newObj[tactic]++;
          } else {
            newObj[tactic] = 1;
          }
        });
      }
    });
    return newObj;
  },
  topLevelTTPs: (state, getters, rootState, rootGetters) => {
    const accountTTPs = getters.orpheusTTPs;
    if (!accountTTPs) return null;
    let topLevels = {};

    accountTTPs.forEach((d) => {
      if (d.technique_id.includes(".")) return;
      topLevels[d.technique_id] = d.technique.name;
    });

    return topLevels;
  },
  orpheusTTPsAll: (state, getters, rootState, rootGetters) => {
    const threat_profiles = getters.orpheus?.threat_profile || null;
    if (!threat_profiles) return null;

    return threat_profiles;
  },
  orpheusThreatScore: (state, getters) => {
    const threatScore = getters.orpheus?.risk_score[0]?.threat_score;
    if (!threatScore) return null;
    return Math.round(threatScore / 10);
  },
  accountTactics: (state, getters, rootState, rootGetters) => {
    const accountTTPs = getters.orpheusTTPs;
    const topLevelTTP = rootState.threat.topLevelTTP;
    if (!accountTTPs || !topLevelTTP) return null;
    let newObj = {};

    let topLevels = {};
    let highest = 0;

    topLevelTTP.forEach((d) => {
      topLevels[d.technique_id] = d.name;
    });

    accountTTPs.forEach((d) => {
      let ttp = null;
      if (d.technique_id.includes("."))
        ttp = topLevels[d.technique_id.split(".")[0]];
      else ttp = topLevels[d.technique_id];
      if (d.technique?.tactics) {
        d.technique.tactics.forEach((k) => {
          const tactic = k.tactic.name;
          if (newObj[tactic]) {
            if (newObj[tactic][ttp]) {
              newObj[tactic][ttp].count = newObj[tactic][ttp].count + d.count;
              if (newObj[tactic][ttp].count > highest)
                highest = newObj[tactic][ttp].count;
            } else {
              newObj[tactic][ttp] = {
                count: 0,
                description: d.technique.description,
                technique_id: d.technique_id.split(".")[0],
                name: ttp,
                tactic: tactic,
              };
              newObj[tactic][ttp].count = d.count;
            }
          } else {
            newObj[tactic] = {};
            newObj[tactic][ttp] = {
              count: 0,
              description: d.technique.description,
              technique_id: d.technique_id.split(".")[0],
              name: ttp,
              tactic: tactic,
            };
            newObj[tactic][ttp].count = d.count;
          }
        });
      }
    });

    return {
      highest,
      map: newObj,
    };
  },
  controlRisksAttackVariety: (state, getters) => {
    const tactics = state.controlrisks_variety;
    let newObj = {};

    if (!tactics) return null;
    tactics.forEach((t) => {
      if (t.tactic_technique__tactic_id !== null) {
        let tactic = toTitleCase(
          t.tactic_technique__tactic_id.replace(/-/g, " ")
        );
        if (newObj[tactic] !== undefined) {
          newObj[tactic]++;
        } else {
          newObj[tactic] = 0;
        }
      }
    });
    return newObj;
  },
  controlRisksVolumeAttackCategory: (state, getters) => {
    const tactics = Object.entries(state.controlrisks_volume);
    let newObj = {};

    if (!tactics) return null;
    tactics.forEach((t) => {
      let tactic = t[0].split("_");
      tactic.shift();
      tactic = toTitleCase(tactic.join(" "));
      newObj[tactic] = t[1];
    });

    return newObj;
  },
  controlRisksMotivations: (state, getters) => {
    const profile = state.controlrisks_profile;
    const industries = profile.industry_threat_groups;
    let motivations = {};

    industries.forEach((industry) => {
      let sectors = industry.control_risks_threat_group_sectors;
      if (sectors.length > 0) {
        sectors.forEach((sector) => {
          if (sector.threat_group) {
            sector.threat_group.motivation?.forEach((motivation) => {
              if (motivations[motivation] === undefined) {
                motivations[motivation] = 0;
              } else {
                motivations[motivation]++;
              }
            });
          }
        });
      }
    });

    return motivations;
  },
  controlRisksHighRiskBehaviours: (state, getters, rootState) => {
    const topLevelTTP = rootState.threat.topLevelTTP;
    if (!topLevelTTP) return null;
    let topLevels = {};

    topLevelTTP.forEach((d) => {
      topLevels[d.technique_id] = d.name;
    });

    const profile = state.controlrisks_profile;
    if (profile === null) return;
    const industries = profile.industry_threat_groups;
    let hrb = {};

    industries.forEach((industry) => {
      if (industry === null) return;
      let sectors = industry.control_risks_threat_group_sectors;
      if (sectors.length > 0) {
        sectors.forEach((sector) => {
          if (sector === null) return;
          if (sector.threat_group) {
            sector.threat_group?.techniques.forEach((t) => {
              const technique = t.technique;
              if (technique === null) return;
              const name = topLevels[technique.technique_id.split(".")[0]];
              if (hrb[name]) {
                hrb[name].score++;
              } else {
                hrb[name] = {
                  technique_id: technique.technique_id.split(".")[0],
                  tactic: technique.tactics[0]?.tactic?.name,
                  description: technique.description,
                  score: 1,
                  name: name,
                };
              }
            });
          }
        });
      }
    });

    let sorted = Object.entries(hrb)
      .map((x) => x[1])
      .sort((a, b) => b.score - a.score)
      .slice(0, 10);

    return sorted;
  },
  controlRisksThreatGroups: (state, getters) => {
    const profile = state.controlrisks_profile;
    if (profile === null) return;
    const industries = profile.industry_threat_groups;
    let groups = [];

    industries.forEach((industry) => {
      if (industry === null) return;
      let sectors = industry.control_risks_threat_group_sectors;
      if (sectors.length > 0) {
        sectors.forEach((sector) => {
          if (sector === null) return;
          if (sector.threat_group) {
            groups.push({
              actor: sector.threat_group.actor,
              country: sector.threat_group.country,
              description: sector.threat_group.description,
              observed_countries: sector.threat_group.observed_countries,
              observed_sectors: sector.threat_group.observed_sectors,
              motivation: sector.threat_group.motivation,
            });
          }
        });
      }
    });

    return groups;
  },
};

const actions = {
  getControlRisksThreatProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      const { data } = await query({
        query: gql_controlrisks_threat_profile,
        variables: {
          id: payload,
        },
      });
      try {
        if (data) {
          const { account } = data;
          commit("SET_CONTROLRISKS_PROFILE", account);
          resolve();
        }
        reject({ error: data, type: "No data" });
      } catch (e) {
        reject({ error: e, type: "Connection error" });
      }
    });
  },
  getControlRisksVariety({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      const { data } = await query({
        query: gql_controlrisks_tactics_variety,
        variables: {
          id: payload,
        },
      });
      try {
        if (data) {
          const { control_risks_accounts_unique_tactics } = data;
          commit(
            "SET_CONTROLRISKS_VARIETY",
            control_risks_accounts_unique_tactics
          );
          resolve();
        }
        reject({ error: data, type: "No data" });
      } catch (e) {
        reject({ error: e, type: "Connection error" });
      }
    });
  },
  getControlRisksVolume({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      const { data } = await query({
        query: gql_controlrisks_tactics_volume,
        variables: {
          id: payload,
        },
      });
      try {
        if (data) {
          const { control_risks_account_tactic_count } = data;
          commit(
            "SET_CONTROLRISKS_VOLUME",
            control_risks_account_tactic_count[0]
          );
          resolve();
        }
        reject({ error: data, type: "No data" });
      } catch (e) {
        reject({ error: e, type: "Connection error" });
      }
    });
  },
  getThreatProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      const { data } = await query({
        query: gql_orpheus_threat_profile,
        variables: {
          account_id: payload,
        },
      });
      try {
        if (data) {
          const { orpheus_company } = data;
          commit("SET_ORPHEUS", orpheus_company);

          resolve();
        }
        reject({ error: data, type: "No data" });
      } catch (e) {
        reject({ error: e, type: "Connection error" });
      }
    });
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_CONTROLRISKS(state, content) {
    state.controlrisks = content[0];
  },
  SET_CONTROLRISKS_PROFILE(state, content) {
    state.controlrisks_profile = content[0];
  },
  SET_CONTROLRISKS_VARIETY(state, content) {
    state.controlrisks_variety = content;
  },
  SET_CONTROLRISKS_VOLUME(state, content) {
    state.controlrisks_volume = content;
  },
  SET_ORPHEUS(state, content) {
    getters.orpheus = content[0];
  },
  SET_TTPS(state, content) {
    state.controlrisksTTPs = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
