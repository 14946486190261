import gqlGetTactics from "@/graphql/account/get_tactics.gql";
import gqlIndustryCountsOfTTPs from "@/graphql/account/industry_counts_of_ttps.gql";
import gql_account_threat_groups from "@/graphql/account/account_threat_groups.gql";
import gqlGetAllTopLevel from "@/graphql/ti/get_all_top_level.gql";
import chroma from "chroma-js";
import { query } from "@/api";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const defaultStoreState = () => ({
  selectedThreatActor: null,
  industryCountTTP: null,
  tactics: null,
  topLevelTTP: null,
  accountThreatGroups: null,
  accountThreatGroupMotivations: null,
});

const state = defaultStoreState();

const getters = {
  computedAccountThreatGroupMotivations: (state) => state.accountThreatGroupMotivations,
  computedAccountThreatGroups: (state) => state.accountThreatGroups,
  industryThreatGroups: (state, getters, rootState, rootGetters) =>
    rootGetters["account/currentAccountDetail"]?.industry_threat_groups
      ? rootGetters["account/currentAccountDetail"].industry_threat_groups
      : null,
  accountTTPsRaw: (state, getters) => {
    const industryThreatGroups = getters.industryThreatGroups;
    if (!industryThreatGroups) return null;
    let newArr = [];
    industryThreatGroups.forEach((ifg) => {
      if (!ifg.threat_group_sectors) return;
      ifg.threat_group_sectors.forEach((tgs) => {
        if (!tgs.threat_group) return;
        tgs.threat_group.techniques.forEach((t) => {
          newArr.push(t);
        });
      });
    });
    return newArr.sort((a, b) => b.score - a.score);
  },
  accountTTPs: (state, getters) => {
    const accountTTPsRaw = getters.accountTTPsRaw;
    if (!getters.accountTTPsRaw) return null;
    let cleanArr = [];

    accountTTPsRaw.forEach((d) => {
      const check = cleanArr.findIndex(
        (f) => f.technique_id === d.technique_id
      );
      if (check === -1) {
        cleanArr.push({
          ...d,
          count: 1,
        });
      } else {
        cleanArr[check] = {
          ...cleanArr[check],
          count: cleanArr[check].count + 1,
          score: d.score
            ? cleanArr[check].score + d.score
            : cleanArr[check].score,
        };
      }
    });
    return cleanArr;
  },
  threatStatus: (state, getters, rootState, rootGetters) => {
    const accountDetail = rootGetters["account/currentAccountDetail"];
    const threatScore = getters.threatScore;
    if (!accountDetail || !accountDetail.industry) {
      return "No industry";
    } else if (threatScore === null) {
      return "No data for industry";
    } else if (threatScore) {
      return "Data found";
    } else {
      return "Loading";
    }
  },
  computeRank: (state, getters, rootState, rootGetters) => {
    const accountDetail = rootGetters["account/currentAccountDetail"];
    const ranks = state.industryCountTTP;
    if (!accountDetail || !ranks) return null;
    if (!accountDetail.industry) return null;

    return ranks.filter(
      (f) =>
        f.industry_threat_group__standard_industry_id === accountDetail.industry
    )[0];
  },
  threatVolume: (state, getters) => {
    if (!getters.computeRank) return null;
    return Math.floor(((54 - getters.computeRank.count_rank) / 54) * 100);
  },
  threatVariety: (state, getters) => {
    if (!getters.computeRank) return null;
    return Math.floor(
      ((54 - getters.computeRank.distinct_count_rank) / 54) * 100
    );
  },
  threatScore: (state, getters) => {
    const threatVariety = getters.threatVariety;
    const threatVolume = getters.threatVolume;
    if (!threatVariety || !threatVolume) return null;
    return Math.round(((threatVariety * threatVolume) / 10000) * 100);
  },
  industryCountTTP: (state) =>
    state.industryCountTTP
      ? state.industryCountTTP.map((d) => {
          const threatVolume = d.count_rank
            ? Math.floor(((54 - d.count_rank) / 54) * 100)
            : null;
          const threatVariety = d.count_rank
            ? Math.floor(((54 - d.distinct_count_rank) / 54) * 100)
            : null;
          const threatScore =
            threatVariety && threatVolume
              ? Math.round(((threatVariety * threatVolume) / 10000) * 100)
              : null;
          return {
            ...d,
            threatVolume,
            threatVariety,
            threatScore,
          };
        })
      : null,
  topLevelTTP: (state) => state.topLevelTTP,
  tactics: (state) => state.tactics,
  selectedThreatActor: (state) => state.selectedThreatActor,
  accountTacticsGradient: (state, getters) => {
    if (!getters.accountTactics) return null;
    return chroma
      .scale(["#263239", "#cb2d3e"])
      .mode("lab")
      .colors(getters.accountTactics.highest);
  },
  accountTechniquesAll: (state, getters) => {
    if (!getters.accountTactics) return null;
    const map = getters.accountTactics.map;
    let newArr = [];
    Object.entries(map).forEach((d) => {
      Object.entries(d[1]).forEach((k) => {
        newArr.push(k[1]);
      });
    });
    return newArr.sort((a, b) => b.score - a.score);
  },
  threatIndexThresholdsForAudit: (state, getters, rootState, rootGetters) => {
    const threatIntelligenceType =
      rootGetters["account/threatIntelligenceType"];
    const orpheusTTPsAll = rootGetters["ti/orpheusTTPsAll"];
    const accountTechniquesAll = getters.accountTechniquesAll;
    const TTPsAll =
      threatIntelligenceType === "orpheus"
        ? orpheusTTPsAll
        : accountTechniquesAll;
    if (!TTPsAll || !TTPsAll.length) return null;
    return {
      medium: Math.round(TTPsAll.length * 0.33),
      high: Math.round(TTPsAll.length * 0.66),
    };
  },
  accountTechniques: (state, getters) => {
    if (!getters.accountTactics) return null;
    const map = getters.accountTactics.map;
    let newArr = [];
    Object.entries(map).forEach((d) => {
      Object.entries(d[1]).forEach((k) => {
        newArr.push(k[1]);
      });
    });
    return newArr.sort((a, b) => b.score - a.score).slice(0, 10);
  },
  accountTactics: (state, getters) => {
    const accountTTPs = getters.accountTTPs;
    const tactics = state.tactics;
    if (!tactics || !accountTTPs) return null;
    let newObj = {};

    let topLevels = {};
    let highest = 0;

    accountTTPs.forEach((d) => {
      if (d.technique_id.includes(".")) return;
      topLevels[d.technique_id] = d.technique.name;
    });

    accountTTPs.forEach((d) => {
      let ttp = null;
      if (d.technique_id.includes("."))
        ttp = topLevels[d.technique_id.split(".")[0]];
      else ttp = topLevels[d.technique_id];
      if (d.technique?.tactics) {
        d.technique.tactics.forEach((k) => {
          const tactic = k.tactic.name;
          if (newObj[tactic]) {
            if (newObj[tactic][ttp]) {
              newObj[tactic][ttp].score = newObj[tactic][ttp].score + d.score;
              if (newObj[tactic][ttp].score > highest)
                highest = newObj[tactic][ttp].score;
            } else {
              newObj[tactic][ttp] = {
                score: 0,
                description: d.technique.description,
                technique_id: d.technique_id.split(".")[0],
                name: ttp,
                tactic: tactic,
              };
              newObj[tactic][ttp].score = d.score;
            }
          } else {
            newObj[tactic] = {};
            newObj[tactic][ttp] = {
              score: 0,
              description: d.technique.description,
              technique_id: d.technique_id.split(".")[0],
              name: ttp,
              tactic: tactic,
            };
            newObj[tactic][ttp].score = d.score;
          }
        });
      }
    });

    return {
      highest,
      map: newObj,
    };
  },
  accountAttackVariety: (state, getters) => {
    const accountTTPs = getters.accountTTPs;
    if (!accountTTPs) return null;
    let newObj = {};

    accountTTPs.forEach((d) => {
      if (d.technique?.kill_chain_phases) {
        d.technique.kill_chain_phases.forEach((k) => {
          const tactic = toTitleCase(k.phase_name.replace(/-/, " "));
          if (newObj[tactic]) {
            newObj[tactic]++;
          } else {
            newObj[tactic] = 1;
          }
        });
      }
    });
    return newObj;
  },
  accountVolumeAttackCategory: (state, getters) => {
    const accountTTPsRaw = getters.accountTTPsRaw;
    if (!accountTTPsRaw) return null;
    let newObj = {};

    accountTTPsRaw.forEach((d) => {
      if (d.technique?.kill_chain_phases) {
        d.technique.kill_chain_phases.forEach((k) => {
          const tactic = toTitleCase(k.phase_name.replace(/-/, " "));
          if (newObj[tactic]) {
            newObj[tactic]++;
          } else {
            newObj[tactic] = 1;
          }
        });
      }
    });
    return newObj;
  },
};

const actions = {
  setActiveThreatActor({ commit }, payload) {
    commit("SET_ACTIVE_THREAT_ACTOR", payload);
  },
  async getAllTopLevel({ commit }) {
    const {
      data: { techniques },
    } = await query({
      query: gqlGetAllTopLevel,
    });

    commit("SET_TOP_LEVEL_TTP", techniques);
  },
  async getTactics({ commit }) {
    const {
      data: { tactic },
    } = await query({
      query: gqlGetTactics,
    });

    commit("SET_TACTICS", tactic);
  },
  async getIndustryCountsOfTTPs({ commit }) {
    const {
      data: { industry_counts_of_ttps },
    } = await query({
      query: gqlIndustryCountsOfTTPs,
    });

    commit("SET_INDUSTRY_COUNT_TTP", industry_counts_of_ttps);
  },
  async fetchAccountThreatGroups({ commit, rootGetters }) {
    const {
      data: { account_by_pk },
    } = await query({
      query: gql_account_threat_groups,
      variables: {
        account_id: rootGetters["account/currentAccount"] ||
        "00000000-0000-0000-0000-000000000000",
      },
    });

    commit("SET_ACCOUNT_THREAT_GROUPS", account_by_pk);
    commit("SET_ACCOUNT_THREAT_GROUP_MOTIVATIONS", account_by_pk);
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_TOP_LEVEL_TTP(state, content) {
    state.topLevelTTP = content;
  },
  SET_ACCOUNT_THREAT_GROUPS(state, content) {
    state.accountThreatGroups =
      content?.industry_info?.mapped?.[0]?.threat_groups;
  },
  SET_ACCOUNT_THREAT_GROUP_MOTIVATIONS(state, content) {
    state.accountThreatGroupMotivations =
      content?.industry_info?.mapped?.[0]?.threat_group_motivations;
  },
  SET_ACTIVE_THREAT_ACTOR(state, content) {
    state.selectedThreatActor = content;
  },
  SET_INDUSTRY_COUNT_TTP(state, content) {
    state.industryCountTTP = content;
  },
  SET_TACTICS(state, content) {
    state.tactics = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
