import getIndustry from "@/graphql/industry.gql";
import getAllAssessors from "@/graphql/all_assessors.gql";
import getAllCountries from "@/graphql/metadata/all_countries.gql";
import gql_metadata_device_types from "@/graphql/metadata/get_device_types.gql";
import { query } from "@/api";

const state = {
  assessors: null,
  countries: null,
  deviceTypes: null,
  industries: null,
  noAnswerOptions: [
    { label: "Known and Accepted Risk", value: "Known and Accepted Risk" },
    {
      label: "Is on the roadmap (0-6 months)",
      value: "Is on the roadmap (0-6 months)",
    },
    {
      label: "Is on the roadmap (6-18 months)",
      value: "Is on the roadmap (6-18 months)",
    },
    {
      label: "Is on the roadmap (18-36 months)",
      value: "Is on the roadmap (18-36 months)",
    },
    { label: "Not considered", value: "Not considered" },
    {
      label: "Not operated due to resource constraints",
      value: "Not operated due to resource constraints",
    },
    { label: "Too expensive", value: "Too expensive" },
    { label: "Other", value: "Other" },
  ],
  maturityOptions: [
    {
      label: "Initial",
      value: "Initial",
      note: "Unpredictable process that is poorly controlled and reactive",
      order: 1,
      color: "bg-initial text-white",
    },
    {
      label: "Repeatable",
      value: "Repeatable",
      note: "Reactive and meant for projects",
      order: 2,
      color: "bg-repeatable text-white",
    },
    {
      label: "Defined",
      value: "Defined",
      note: "Proactive process meant for organisations",
      order: 3,
      color: "bg-defined",
    },
    {
      label: "Managed",
      value: "Managed",
      note: "Measured and controlled process",
      order: 4,
      color: "bg-managed",
    },
    {
      label: "Optimizing",
      value: "Optimizing",
      note: "Focus will be on continuous process improvement",
      order: 5,
      color: "bg-optimising",
    },
  ],
  deploymentOptions: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Some", value: "Some" },
    { label: "N/A", value: "N/A" },
    { label: "Follow-up Required", value: "Follow-up Required" },
  ],
  companySizeOptions: [
    { label: "1-10", value: "1-10" },
    { label: "11-50", value: "11-50" },
    { label: "51-200", value: "51-200" },
    { label: "201-500", value: "201-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1001-5000", value: "1001-5000" },
    { label: "5001-10000", value: "5001-10000" },
    { label: "10001+", value: "10001+" },
  ],
  companyRevenueOptions: [
    { label: "£0-£1m", value: "£0-£1m" },
    { label: "£1m-£10m", value: "£1m-£10m" },
    { label: "£10m-£50m", value: "£10m-£50m" },
    { label: "£50m-£100m", value: "£50m-£100m" },
    { label: "£100m-£250m", value: "£100m-£250m" },
    { label: "£250m-£500m", value: "£250m-£500m" },
    { label: "£500m-£1b", value: "£500m-£1b" },
    { label: "£1b+", value: "£1b+" },
  ],
  threatRelevanceOptions: [
    { label: "Medium Threat Relevance", value: "Medium Threat Relevance" },
    { label: "High Threat Relevance", value: "High Threat Relevance" },
  ],
};

const getters = {
  deviceTypes: (state) => state.deviceTypes,
  countries: (state) => state.countries,
  noAnswerOptions: (state) => state.noAnswerOptions,
  maturityOptions: (state) => state.maturityOptions,
  companySizeOptions: (state) => state.companySizeOptions,
  companyRevenueOptions: (state) => state.companyRevenueOptions,
  deploymentOptions: (state) => state.deploymentOptions,
  threatRelevanceOptions: (state) => state.threatRelevanceOptions,
  assessors: (state) => state.assessors,
  industries: (state) => {
    if (!state.industries) return null;
    return state.industries.filter((d) => d.id);
  },
  assessorList(state) {
    if (!state.assessors) return null;
    return state.assessors.map((d) => {
      return {
        id: d.id,
        name: d.first_name + " " + d.last_name,
        job_title: d.job_title,
      };
    });
  },
};

const actions = {
  async getMetadataAssessors({ commit }) {
    try {
      const { data } = await query({
        query: getAllAssessors,
      });
      if (data) {
        return commit("SET_METADATA_ASSESSORS", data.all_users);
      } else {
        throw { error: data, type: "No data" };
      }
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  async getMetadataCountries({ commit }) {
    try {
      const { data } = await query({
        query: getAllCountries,
      });
      if (data) {
        return commit("SET_COUNTRIES", data.country);
      } else {
        throw { error: data, type: "No data" };
      }
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  async getMetadataIndustries({ commit }) {
    try {
      const { data } = await query({
        query: getIndustry,
      });
      if (data) {
        return commit("SET_METADATA_INDUSTRIES", data.industry);
      } else {
        throw { error: data, type: "No data" };
      }
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  async getMetadataDeviceTypes({ commit }) {
    try {
      const { data } = await query({
        query: gql_metadata_device_types,
      });
      if (data) {
        return commit("SET_METADATA_DEVICE_TYPES", data.metadata_by_pk);
      } else {
        throw { error: data, type: "No data" };
      }
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
};
// mutations
const mutations = {
  SET_METADATA_ASSESSORS(state, content) {
    state.assessors = content;
  },
  SET_COUNTRIES(state, content) {
    state.countries = content;
  },
  SET_METADATA_INDUSTRIES(state, content) {
    state.industries = content;
  },
  SET_METADATA_DEVICE_TYPES(state, content) {
    state.deviceTypes = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
