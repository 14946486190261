<template>
  <div v-if="userRole === 'superadmin'" class="-mt-3 flex flex-col bg-arco-dark-card">
    <div class="mb-5 flex">
      <template v-for="tab in tabs" :key="tab.value">
        <button
          @click="activeTab = tab.value"
          class="router-link-item -px-1 -mb-px inline-flex h-10 items-center whitespace-nowrap bg-transparent p-2 py-6 text-center text-sm focus:outline-none sm:px-4"
          :class="{ 'border-b-2 border-blue-500 text-blue-500': isActiveTab(tab.value), 'border-b-2 border-transparent text-white': !isActiveTab(tab.value) }"
        >
          <el-icon :class="tab.icon" /> {{ tab.label }}
        </button>
      </template>
    </div>

    <div v-if="activeTab === 'account_tree'">
      <div class="w-full">
        <p class="font-title text-xl text-arco-t-primary-dark">Account Navigator</p>
        <p class="text-xs text-arco-t-primary-dark">Search and select an account you would like to view using the tree hierarchy</p>
      </div>
      <el-input v-model="searchQuery" placeholder="Search by account name" class="my-5" clearable @input="onQueryChanged">
        <template #prepend>
          <el-button><el-icon class="ai ai-Search-02-1"></el-icon></el-button>
        </template>
      </el-input>
      <el-tree ref="treeRef" class="bg-transparent text-white" :data="data" :props="{ ...props, class: customNodeClass }" :filter-node-method="filterMethod" :height="700">
        <template #default="{ node }">
          <div
            class="my-2 w-full rounded-lg border border-gray-700 p-2"
            :class="{
              'bg-gradient-to-r from-blue-400/40 to-blue-800/40 hover:from-blue-400/80 hover:to-blue-800/80': node.data.org_type === 'Portfolio',
              'bg-gradient-to-r from-orange-400/40 to-orange-800/40 hover:from-orange-400/80 hover:to-orange-800/80': node.data.org_type === 'Sub-Portfolio',
              'bg-gradient-to-r from-green-400/40 to-green-800/40 hover:from-green-400/80 hover:to-green-800/80': node.data.org_type === 'Group',
            }"
          >
            <div class="flex items-center justify-between">
              <div>{{ node.label }}</div>
              <el-button @click.stop="changeOrgAccount(node.data.org_id)" size="small"><el-icon class="ai ai-Out-01"></el-icon></el-button>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
    <div v-else-if="activeTab === 'account_list'">
      <el-input ref="search" placeholder="Search..." class="p-3" v-model="searchQuery" />
      <ul
        class="w-full divide-y divide-gray-700 overflow-hidden rounded-md text-white transition duration-100 ease-in focus:outline-none"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-0"
      >
        <template v-if="!searchQuery">
          <el-divider v-if="recentAccounts.length > 0" class="my-0 mt-2 bg-arco-dark-card"><span class="text-xs text-white/40">Recently Viewed</span></el-divider>

          <li v-for="recentItem in recentAccounts" :key="recentItem.id" @click="changeAccount(recentItem)" class="cursor-pointer bg-arco-dark-card p-4 text-sm text-white hover:text-blue-400" id="listbox-option-0" role="option">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <span class="flex flex-row items-center font-normal">
                  <img v-if="getLogo(recentItem)" :src="getLogo(recentItem)" class="mr-2 h-[35px] w-[35px] rounded-full object-cover" />
                  <CompanyLogoPlaceholder v-else :title="recentItem.name" class="mr-2 h-[35px] w-[35px] rounded-full border border-gray-700 text-arco-t-primary-dark" />
                  {{ recentItem.name }}
                </span>
                <i class="ai ai-Trash rounded-lg bg-arco-dark-card p-2 text-white/40 transition-all hover:bg-arco-dark-card-2 hover:text-white/80" @click.stop="removeRecent(recentItem.id)"></i>
              </div>
            </div>
          </li>
          <el-divider v-if="recentAccounts.length > 0" class="my-0"><span class="text-xs text-white/40">All Accounts</span></el-divider>
        </template>

        <li v-for="item in accountSearchFilter" :key="item.id" @click="changeAccount(item)" class="cursor-pointer bg-arco-dark-card p-4 text-sm text-white hover:text-blue-400" id="listbox-option-0" role="option">
          <div class="flex flex-col">
            <div class="flex justify-between">
              <span class="flex flex-row items-center font-normal">
                <img v-if="getLogo(item)" :src="getLogo(item)" class="mr-2 h-[35px] w-[35px] rounded-full object-cover" />
                <CompanyLogoPlaceholder v-else :title="item.name" class="mr-2 h-[35px] w-[35px] rounded-full border border-gray-700 text-arco-t-primary-dark" />
                {{ item.name }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div v-if="features?.indexOf('mssp_account_switcher_volaris') > -1">
      <div class="w-full">
        <p class="font-title text-xl text-arco-t-primary-dark">Account Navigator</p>
        <p class="text-xs text-arco-t-primary-dark">Search and select an account you would like to view using the tree hierarchy</p>
      </div>
      <el-input v-model="searchQuery" placeholder="Search by account name" class="my-5" clearable @input="onQueryChanged">
        <template #prepend>
          <el-button><el-icon class="ai ai-Search-02-1"></el-icon></el-button>
        </template>
      </el-input>
      <el-tree ref="treeRef" class="bg-transparent text-white" :data="data" :props="{ ...props, class: customNodeClass }" :filter-node-method="filterMethod" :height="700">
        <template #default="{ node }">
          <div
            class="my-2 w-full rounded-lg border border-gray-700 p-2"
            :class="{
              'bg-gradient-to-r from-blue-400/40 to-blue-800/40 hover:from-blue-400/80 hover:to-blue-800/80': node.data.org_type === 'Portfolio',
              'bg-gradient-to-r from-orange-400/40 to-orange-800/40 hover:from-orange-400/80 hover:to-orange-800/80': node.data.org_type === 'Sub-Portfolio',
              'bg-gradient-to-r from-green-400/40 to-green-800/40 hover:from-green-400/80 hover:to-green-800/80': node.data.org_type === 'Group',
            }"
          >
            <div class="flex items-center justify-between">
              <div>{{ node.label }}</div>
              <el-button @click.stop="changeOrgAccount(node.data.org_id)" size="small"><el-icon class="ai ai-Out-01"></el-icon></el-button>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
    <div v-else>
      <el-input ref="search" placeholder="Search..." class="p-3" v-model="searchQuery" />
      <ul
        class="w-full divide-y divide-gray-700 overflow-hidden rounded-md text-white transition duration-100 ease-in focus:outline-none"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-0"
      >
        <template v-if="!searchQuery">
          <el-divider v-if="recentAccounts.length > 0" class="my-0 mt-2 bg-arco-dark-card"><span class="text-xs text-white/40">Recently Viewed</span></el-divider>

          <li v-for="recentItem in recentAccounts" :key="recentItem.id" @click="changeAccount(recentItem)" class="cursor-pointer bg-arco-dark-card p-4 text-sm text-white hover:text-blue-400" id="listbox-option-0" role="option">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <span class="flex flex-row items-center font-normal">
                  <img v-if="getLogo(recentItem)" :src="getLogo(recentItem)" class="mr-2 h-[35px] w-[35px] rounded-full object-cover" />
                  <CompanyLogoPlaceholder v-else :title="recentItem.name" class="mr-2 h-[35px] w-[35px] rounded-full border border-gray-700 text-arco-t-primary-dark" />
                  {{ recentItem.name }}
                </span>
                <i class="ai ai-Trash rounded-lg bg-arco-dark-card p-2 text-white/40 transition-all hover:bg-arco-dark-card-2 hover:text-white/80" @click.stop="removeRecent(recentItem.id)"></i>
              </div>
            </div>
          </li>
          <el-divider v-if="recentAccounts.length > 0" class="my-0"><span class="text-xs text-white/40">All Accounts</span></el-divider>
        </template>

        <li v-for="item in accountSearchFilter" :key="item.id" @click="changeAccount(item)" class="cursor-pointer bg-arco-dark-card p-4 text-sm text-white hover:text-blue-400" id="listbox-option-0" role="option">
          <div class="flex flex-col">
            <div class="flex justify-between">
              <span class="flex flex-row items-center font-normal">
                <img v-if="getLogo(item)" :src="getLogo(item)" class="mr-2 h-[35px] w-[35px] rounded-full object-cover" />
                <CompanyLogoPlaceholder v-else :title="item.name" class="mr-2 h-[35px] w-[35px] rounded-full border border-gray-700 text-arco-t-primary-dark" />
                {{ item.name }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useStructureStore } from "@/Stores/structure";
import { query } from "@/api";
import type { TreeNode } from "element-plus/es/components/tree-v2/src/types";
import { useStore } from "vuex";
import get_account_from_ref from "@/graphql/account/get_account_from_ref.gql";
const accountListAll = computed(() => vuexStore.getters["account/accountListAll"]);
const recentAccounts = computed(() => vuexStore.getters["account/recentAccounts"]);
const features = computed(() => vuexStore.getters["tenant/features"]);
const userRole = computed(() => vuexStore.getters["user/userRole"]);
const openAccountList = ref(false);
const accountSearchFilter = computed(() => {
  const query = searchQuery.value;
  const accounts = accountListAll.value;
  if (!accounts) return null;
  if (query && query.length > 1) {
    return accounts
      .filter((x) => x.name !== null)
      .filter((f) => f.name.toLowerCase().includes(query.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  return accounts.filter((x) => x.name !== null).sort((a, b) => a.name.localeCompare(b.name));
});
const tabs = ref([
  { label: "Account List", value: "account_list", icon: "ai ai-List-View-Rectangle mr-2" },
  { label: "Account Navigator", value: "account_tree", icon: "ai ai-Sort-Circle mr-2" },
]);
const activeTab = ref("account_list");
const isActiveTab = (value) => {
  return activeTab.value === value;
};
function getLogo(company) {
  if (company.logo) return company.logo;
  if (company.company?.has_logo) return `https://cdn-uk1.redsmart.app/company-data-files/logos/${company?.company_id}.png`;
  return null;
}
async function changeAccount(account) {
  searchQuery.value = null;
  if (!account.id) return;
  await vuexStore.dispatch("account/updateRecentAccounts", account);
  await vuexStore.dispatch("user/updateUser", {
    userUpdateObj: {
      active_account_id: account.id,
    },
  });
  vuexStore.dispatch("ui/closeDrawer");
  return onClickOutside();
}
const changeOrgAccount = async (org_id) => {
  if (!org_id) return;
  const { data } = await query({
    query: get_account_from_ref,
    variables: {
      ref: org_id,
    },
  });
  try {
    await vuexStore.dispatch("user/updateUser", {
      userUpdateObj: {
        active_account_id: data.account[0].id,
        // active_mssp_id: null,
      },
    });
    vuexStore.dispatch("ui/closeDrawer");
    searchQuery.value = "";
    onQueryChanged();
  } catch (e) {
    console.error(e);
  }
};
function removeRecent(id) {
  store.dispatch("account/removeRecentAccount", id);
}

function onClickOutside() {
  openAccountList.value = false;
  searchQuery.value = null;
}
const store = useStructureStore();
const vuexStore = useStore();
interface Tree {
  id: string;
  label: string;
  children?: Tree[];
}
const customNodeClass = (data) => {
  let nodeClass = "node scroll-thin";

  return nodeClass;
};
const searchQuery = ref("");
const treeRef = ref<InstanceType<typeof ElTreeV2>>();
const data = computed(() => {
  return store.structure;
});
const props = {
  value: "org_id",
  label: "name",
  children: "children",
};
const emit = defineEmits(["close"]);

const onQueryChanged = (queryString: string) => {
  // TODO: fix typing when refactor tree-v2
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  treeRef.value!.filter(queryString);
};
const filterMethod = (queryString: string, node: TreeNode) => {
  return node.name.toLowerCase()!.includes(queryString?.toLowerCase() || "");
};
</script>

<style lang="scss">
.el-tree-node__content:hover {
  background-color: transparent !important;
}

.el-tree-node:focus > .el-tree-node__content {
  background-color: transparent !important;
}

.node {
  @apply mt-2 flex flex-col justify-between gap-2 rounded-lg p-2;
}

.border-blue {
  @apply border-blue-500;
}

.border-green {
  @apply border-green-500;
}

.border-orange {
  @apply border-orange-500;
}

.account-switch {
  @apply rounded-lg p-2 transition-colors hover:bg-arco-dark-card;
}

.scroll-thin {
  scrollbar-width: thin;
}
</style>
