import { query } from "@/api";
import get_heatmap_weights from "@/graphql/performance/get_heatmap_weights.gql";
import { heatmapDemo } from "@/store/static/heatmap_demo";

const colourMap = {
  "75, 84, 98": 0, // gray
  "203, 45, 62": 1, // red
  "239, 71, 58": 2, // dark orange
  "243, 115, 53": 3, // orange
  "253, 200, 48": 4, // yellow
  "56, 239, 125": 5, // green
};
const colourScale = [
  { normal: "75, 84, 98", blind: "75, 84, 98" }, // gray / notValid
  { normal: "203, 45, 62", blind: "73, 0, 146" }, // red
  { normal: "239, 71, 58", blind: "73, 107, 248" }, // dark orange
  { normal: "243, 115, 53", blind: "182, 109, 255" }, // orange
  { normal: "253, 200, 48", blind: "109, 182, 255" }, // yellow
  { normal: "56, 239, 125", blind: "182, 219, 255" }, // green
];
const colourScaleCyberHealth = [
  // RGB colors
  { normal: "203, 45, 62", blind: "73, 0, 146" }, // RED
  { normal: "240, 88, 73", blind: "98, 45, 152" }, //
  { normal: "239, 71, 58", blind: "73, 107, 248" }, // DARK ORANGE
  { normal: "248, 124, 73", blind: "113, 119, 255" }, //
  { normal: "243, 115, 53", blind: "182, 109, 255" }, // ORANGE
  { normal: "248, 174, 73", blind: "190, 132, 249" }, //
  { normal: "248, 205, 73", blind: "197, 142, 255" }, //
  { normal: "253, 200, 48", blind: "109, 182, 255" }, // YELLOW
  { normal: "164, 248, 73", blind: "144, 199, 255" }, //
  { normal: "56, 239, 125", blind: "182, 219, 255" }, // GREEN
];

const defaultData = {
  operational: {
    color: { normal: "75, 84, 98", blind: "75, 84, 98" },
    value: "exempt",
  },
  deployment: {
    color: { normal: "75, 84, 98", blind: "75, 84, 98" },
    value: "exempt",
  },
  tool: "Undeployed",
};

const heatmap = heatmapDemo;

export default {
  namespaced: true,

  state: {
    colourMap: colourMap,
    colourScale: colourScale,
    colourScaleCyberHealth: colourScaleCyberHealth,
    filtered: null,
    settings: null,
    defaultData: defaultData,
    heatmap: heatmap,
  },

  getters: {
    heatmap: (state) => {
      return state.heatmap;
    },
    colourScaleCyberHealth: (state) => {
      return state.colourScaleCyberHealth;
    },
  },

  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_HEATMAP(state, heatmap) {
      state.heatmap = heatmap;
    },
    SET_FILTERED(state, filtered) {
      state.filtered = filtered;
    },
  },

  actions: {
    async getProductSettings({ commit }) {
      try {
        const { data } = await query({
          query: get_heatmap_weights,
        });

        if (data) {
          const { performance_heatmap_weights } = data;
          commit(
            "SET_SETTINGS",
            normaliseSettings(performance_heatmap_weights[0])
          );
        }
      } catch (e) {
        console.error(e);
      }
    },

    async setHeatmap({ dispatch, commit }, structure) {
      let normalised = [];
      try {
        await dispatch("getProductSettings");
        normalised = normaliseHeatmapData(structure);
        await getAggregateScore(normalised);
      } catch (e) {
        console.error(e);
      }

      const heatmap = normalised.map((org) => {
        return {
          data: org.heatmap.data,
          org_name: org.name,
          org_type: org.org_type,
          org_id: org.org_id,
          reports_to: org.reports_to ?? null,
          score: org.score,
          aggregated_score: org.aggregated_score,
          cyber_health_rank: org.cyber_health_rank,
        };
      });
      commit("SET_HEATMAP", heatmap);
    },

    filterHeatmap({ state, commit }, org_id) {
      const parent = state.heatmap.find((o) => o.org_id === org_id);
      commit("SET_FILTERED", [
        parent,
        ...recursiveFilter(state.heatmap, org_id),
      ]);
    },
  },

  // ... Include the rest of your utility functions here as they were in the original Pinia store.
  // Functions such as `getColour`, `getScore`, `getAggregateScore`, etc. remain as regular JavaScript functions
  // since they do not directly modify the state.
};

const setCapabilityData = (capability, data) => {
  if (!data) {
    return {
      operational: {
        color: colourScale.value[0],
        value: "!",
      },
      deployment: {
        color: colourScale.value[0],
        value: "!",
      },
      tool: "Undeployed",
    };
  }
  const { deployment, operational } = data;

  const operationalData = {
    color: {},
    value: "",
  };

  const deploymentData = {
    color: {},
    value: "!",
  };

  if (operational.ineligible) {
    operationalData.color = { normal: "31, 41, 55", blind: "31, 41, 55" };
    operationalData.value = "exempt";
  } else if (operational.critical) {
    operationalData.color = colourScale[1];
  } else {
    operationalData.color = getColour(
      capability,
      "operational",
      operational.value_per_asset !== undefined
        ? operational.value_per_asset
        : operational.value
    );
    operationalData.value = operational.value;
  }

  if (deployment.ineligible) {
    deploymentData.color = { normal: "31, 41, 55", blind: "31, 41, 55" };
    deploymentData.value = "exempt";
  } else if (deployment.critical) {
    deploymentData.color = colourScale[1];
  } else {
    deploymentData.color = getColour(
      capability,
      "deployment",
      deployment.value_per_asset !== undefined
        ? deployment.value_per_asset
        : deployment.value
    );
    deploymentData.value = deployment.value;
  }

  return {
    operational: operationalData,
    deployment: deploymentData,
    tool: data.tool ? data.tool : "Undeployed",
  };
};

// normalize products to their capabilities
const normaliseHeatmapData = (structure) => {
  let normalised = JSON.parse(
    JSON.stringify(structure.filter((s) => s.heatmap !== null))
  );
  normalised.forEach((org) => {
    let heatmapData = org.heatmap?.data || null;
    if (heatmapData?.crowdstrike) {
      console.error(`${org.name} - wrong data type`);
      org.heatmap.data = {
        endpoint_security: defaultData,
        web_application_scans: defaultData,
        email_security: defaultData,
        awareness_training: defaultData,
        digital_risk_management: defaultData,
        web_security_gateway: defaultData,
        vulnerability_management: defaultData,
        mfa: defaultData,
        human_resources: defaultData,
        grc: defaultData,
        third_party_risk_management: defaultData,
      };
    } else if (
      heatmapData === null ||
      (["Portfolio", "Sub-Portfolio", "Group"].includes(org.org_type) &&
        !["Volaris Consolidated", "Lumine Group"].includes(org.name))
    ) {
      org.heatmap.data = {
        endpoint_security: defaultData,
        web_application_scans: defaultData,
        email_security: defaultData,
        awareness_training: defaultData,
        digital_risk_management: defaultData,
        web_security_gateway: defaultData,
        vulnerability_management: defaultData,
        mfa: defaultData,
        human_resources: defaultData,
        grc: defaultData,
        third_party_risk_management: defaultData,
      };
    } else {
      org.heatmap.data = {
        endpoint_security: setCapabilityData(
          "endpoint_security",
          heatmapData.endpoint_security
        ),
        web_application_scans: setCapabilityData(
          "web_application_scans",
          heatmapData.web_application_scans
        ),
        email_security: setCapabilityData(
          "email_security",
          heatmapData.email_security
        ),
        awareness_training: setCapabilityData(
          "awareness_training",
          heatmapData.awareness_training
        ),
        digital_risk_management: setCapabilityData(
          "digital_risk_management",
          heatmapData.digital_risk_management
        ),
        web_security_gateway: setCapabilityData(
          "web_security_gateway",
          heatmapData.web_security_gateway
        ),
        vulnerability_management: setCapabilityData(
          "vulnerability_management",
          heatmapData.vulnerability_management
        ),
        mfa: setCapabilityData("mfa", heatmapData.mfa),
        human_resources: setCapabilityData(
          "human_resources",
          heatmapData.human_resources
        ),
        grc: setCapabilityData("grc", heatmapData.grc),
        third_party_risk_management: setCapabilityData(
          "third_party_risk_management",
          heatmapData.third_party_risk_management
        ),
      };
    }
    org.score = getScore(org.heatmap.data);
  });
  return normalised;
};

// normalize dashboard settings
const normaliseSettings = (settings) => {
  let capabilities = new Set(
    Object.keys(settings).map((k) => {
      let split = k.split("_");
      split.pop();
      return split.join("_");
    })
  );
  let normalised = {};
  capabilities.forEach((capability) => {
    let operational = settings[`${capability}_operational`];
    let deployment = settings[`${capability}_deployment`];
    normalised[capability] = {
      operational: [
        operational.worst,
        operational.bad,
        operational.okay,
        operational.good,
        operational.best,
      ],
      deployment: [
        deployment.worst,
        deployment.bad,
        deployment.okay,
        deployment.good,
        deployment.best,
      ],
    };
  });
  return normalised;
};

const getColour = (capability, type, value) => {
  let thresholds = settings[capability][type];
  let index = thresholds.findIndex((t) => {
    if (value === null || t === null) return false;
    if (t.status) return t.status.includes(value);
    let max = t.max === null ? Infinity : t.max;
    let min = t.min === null ? -Infinity : t.min;
    // console.log(`${capability} ~ ${min} | ${max} | ${value} | ${value >= min}`)
    return value >= min && value < max;
  });
  // console.log(index);
  return index !== -1 ? colourScale.value[index + 1] : colourScale.value[0];
};

const getScore = (capabillities) => {
  const metrics = [];

  Object.entries(capabillities).forEach((c) => {
    if (typeof c[1] !== "object") return;
    if (c[1].operational?.value !== "exempt") metrics.push(c[1].operational);
    if (c[1].deployment?.value !== "exempt") metrics.push(c[1].deployment);
  });

  const squarePercent = 100 / metrics.length / 5;

  let total = 0;
  for (let i = 0; i < metrics.length; i++) {
    const metric = metrics[i];
    total += colourMap.value[metric.color.normal] * squarePercent;
  }

  return total.toFixed(1);
};

const getAggregateScore = async (flat) => {
  let parents = flat.filter((f) =>
    ["Portfolio", "Sub-Portfolio", "Group"].includes(f.org_type)
  );
  for (let i = parents.length - 1; i >= 0; i--) {
    let children = flat.filter(
      (child) => child.reports_to === parents[i].org_id
    );
    let totalScore = children.reduce((acc, currentValue) => {
      if (currentValue.aggregated_score)
        return acc + +currentValue.aggregated_score;
      else return acc + +currentValue.score;
    }, 0);
    let aggregated_score = (totalScore / children.length).toFixed(1);
    parents[i].aggregated_score = aggregated_score;
  }
  return "finished";
};

function recursiveFilter(heatmap, org_id) {
  const filteredArr = [];
  const filter = heatmap.filter((org) => org.reports_to === org_id);
  filter.forEach((f) => {
    filteredArr.push(f);
    filteredArr.push(...recursiveFilter(heatmap, f.org_id));
  });
  return filteredArr;
}

// ... Add your other utility functions here just like `recursiveFilter`.
