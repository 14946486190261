<template>
  <div class="fixed inset-x-0 bottom-0 z-50 pb-2 sm:pb-5" v-if="isOpen">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="rounded-lg bg-blue-400 p-2 shadow-lg sm:p-3" style="background-color: #27248e">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex w-0 flex-1 items-center">
            <p class="mx-2 text-sm text-white">
              We use cookies essential for functionality, as well as for analytics and performance. We'd like to set additional cookies to understand how you use the platform. Find out more
              <router-link to="/cookie-policy" target="_blank" class="text-blue-100">here</router-link>.
            </p>
          </div>
          <div class="order-3 mx-2 mt-2 w-full shrink-0 sm:order-2 sm:mt-0 sm:w-auto" @click="accept">
            <p class="text-navyBlue-400 flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-arco-dark-card px-2 py-1 text-sm font-medium shadow-sm hover:bg-arco-dark-card/60">Accept additional cookies</p>
          </div>
          <div class="order-3 mx-2 mt-2 w-full shrink-0 sm:order-2 sm:mt-0 sm:w-auto" @click="reject">
            <p class="text-navyBlue-400 flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-arco-dark-card px-2 py-1 text-sm font-medium shadow-sm hover:bg-arco-dark-card/60">Reject</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ElNotification } from "element-plus";
export default {
  name: "NavCookies",
  computed: {
    ...mapGetters("user", ["user"]),
    cookieConsent() {
      const user = this.user;
      if (user && user.cookie_accept !== null) return user.cookie_accept;
      return null;
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (this.getCookieConsent() === null) {
      this.isOpen = true;
    }
  },
  methods: {
    getCookieConsent() {
      return localStorage.getItem("cookie_accept");
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem("cookie_accept", true);
      return this.updateCookies(true);
    },
    reject() {
      this.isOpen = false;
      localStorage.setItem("cookie_accept", false);
      return this.updateCookies(false);
    },
    async updateCookies(status) {
      try {
        const result = await this.$store.dispatch("user/updateUser", {
          userUpdateObj: {
            cookie_accept: status,
          },
        });
        if (result) {
          ElNotification({
            title: "Success",
            message: "Preferences saved successfully",
            type: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
