import { query } from "@/api";
import get_account_onboard_by_pk from "@/graphql/account_onboard/get_account_onboard_by_pk.gql";
import gql_insert_account_onboard from "@/graphql/account_onboard/insert_account_onboard.gql";
import gql_update_account_onboard from "@/graphql/account_onboard/update_account_onboard.gql";

const defaultStoreState = () => ({
  account_onboard: null,
});

const state = defaultStoreState();

const getters = {
  accountOnboard: (state) => state.account_onboard,
};

const actions = {
  async getAccountOnboard({ commit, rootGetters }) {
    const currentAccount = rootGetters["account/currentAccount"] || null;
    if (!currentAccount)
      throw { error: "No account", type: "getAccountOnboard" };
    const { data } = await query({
      query: get_account_onboard_by_pk,
      variables: {
        account_id: currentAccount,
      },
    });

    if (data) {
      const { account_onboard_by_pk } = data;
      commit("SET_ACCOUNT_ONBOARD", account_onboard_by_pk);
    } else {
      throw { error: "No data", type: "getAccountOnboard" };
    }
  },
  async insertAccountOnboard({ commit }, payload) {
    const { data } = await query({
      query: gql_insert_account_onboard,
      variables: {
        objects: payload,
      },
    });

    if (data) {
      const { insert_account_onboard } = data;
      const { returning } = insert_account_onboard;
      commit("SET_ACCOUNT_ONBOARD", returning);
    } else {
      throw { error: "No data", type: "insertAccountOnboard" };
    }
  },
  async updateAccountOnboard({ commit }, payload) {
    const { data } = await query({
      query: gql_update_account_onboard,
      variables: {
        account_id: payload.account_id,
        _set: payload._set,
      },
    });

    if (data) {
      const { update_account_onboard_by_pk } = data;
      commit("SET_ACCOUNT_ONBOARD", update_account_onboard_by_pk);
    } else {
      throw { error: "No data", type: "updateAccountOnboard" };
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_ACCOUNT_ONBOARD(state, content) {
    if (content?.account_id) {
      state.account_onboard = content;
    } else if (content.length === 1) {
      state.account_onboard = content[0];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
