const defaultStoreState = () => ({
  loadingStates: {},
});

const state = defaultStoreState();

const mutations = {
  startLoading(state, apiName) {
    state.loadingStates[apiName] = true;
  },
  stopLoading(state, apiName) {
    state.loadingStates[apiName] = false;
  },
};

const actions = {
  startLoading({ commit }, apiName) {
    commit("startLoading", apiName);
  },
  stopLoading({ commit }, apiName) {
    commit("stopLoading", apiName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
