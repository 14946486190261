import { split, concat, ApolloLink } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { createApolloProvider } from "@vue/apollo-option";
import { getMainDefinition } from "@apollo/client/utilities";

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

const getHeaders = () => {
  const headers = {};
  const token = localStorage.getItem("jwt_token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};
// Http endpoint
const httpEndpoint = "https://api.arcocyber.com";
const wsEndpoint = "wss://api.arcocyber.com/v1/graphql";
// Config
const httpLink = createHttpLink({
  uri: httpEndpoint,
});
const errorHandler = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    return forward(operation);
  }
);
const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...getHeaders(),
  },
}));
const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      headers: {
        ...getHeaders(),
      },
    },
  },
});
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  errorHandler.concat(wsLink),
  authLink.concat(errorHandler).concat(httpLink)
);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
});

// Call this in the Vue app file
export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    $subscription: {
      fetchPolicy: "network-only",
    },
    $watchQuery: {
      fetchPolicy: "network-only",
    },
    $mutation: {
      fetchPolicy: "network-only",
    },
  },
});
