<template>
  <div class="theme-dark" id="app">
    <router-view />
    <CookieMessage />
  </div>
</template>

<script>
import CookieMessage from "@/components/Nav/NavCookies.vue";
import { mapGetters } from "vuex";
import { THEME_KEY } from "vue-echarts";
import { computed } from "vue";

export default {
  components: {
    CookieMessage,
  },
  data() {
    return {
      appTheme: "dark",
      primeVueTheme: "arcoDark",
    };
  },
  provide() {
    return {
      appTheme: this.$data,
      [THEME_KEY]: computed(() => {
        return "arcoDark";
      }),
    };
  },

  computed: {
    ...mapGetters("ui", ["VUE_APP_PRIMARY_COLOR", "VUE_APP_PRIMARY_HIGHLIGHT_COLOR", "VUE_APP_SECONDARY_COLOR", "VUE_APP_LOGO", "VUE_APP_LOGO_LOGIN", "VUE_APP_TITLE", "VUE_APP_FAVICON", "VUE_APP_DOC_TEMPLATE"]),
  },
};
</script>
