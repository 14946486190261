import gqlInsertProject from "@/graphql/project/insert_project.gql";
import gqlUpdateProject from "@/graphql/project/update_project.gql";
import gqlDeleteProject from "@/graphql/project/delete_project.gql";
import gqlGetProjects from "@/graphql/project/get_project_by_account_id.gql";
import { query } from "@/api";

const defaultStoreState = () => ({
  projects: [],
});

const state = defaultStoreState();

const getters = {
  accountProjects: (state, _getters, _rootState, rootGetters) => {
    const currentAccountDetail =
      state.projects && state.projects.length > 0
        ? state.projects
        : rootGetters["account/currentAccountDetail"]?.account_projects
        ? rootGetters["account/currentAccountDetail"].account_projects
        : null;
    if (!currentAccountDetail) return null;
    return currentAccountDetail;
  },
};

const actions = {
  async getAccountProjects({ commit, rootGetters }, payload) {
    const currentAccount = rootGetters["account/currentAccount"] || payload;
    if (!currentAccount) {
      throw { error: "No account", type: "getAccountProjects" };
    }
    const { data } = await query({
      query: gqlGetProjects,
      variables: {
        account_id: currentAccount,
      },
    });
    const { project } = data;
    if (project) {
      commit("SET_PROJECTS", project);
    } else {
      throw { error: "No data", type: "getAccountProjects" };
    }
  },
  async insertAccountProject({ commit }, { payload }) {
    if (!payload) {
      throw { error: "No payload provided", type: "insertAccountProject" };
    }

    const { data } = await query({
      query: gqlInsertProject,
      variables: {
        objects: payload,
      },
    });
    if (data) {
      commit("INSERT_PROJECT", payload);
    } else {
      throw { error: "No data", type: "insertAccountProject" };
    }
  },
  async updateAccountProject({ dispatch }, { payload, account_id }) {
    const { data } = await query({
      query: gqlUpdateProject,
      variables: {
        _set: payload,
        id: payload.id,
      },
    });
    const { update_project_by_pk } = data;
    if (update_project_by_pk) {
      dispatch("getAccountProjects", account_id);
    } else {
      throw { error: "No data", type: "updateAccountProject" };
    }
  },
  async deleteAccountProject({ commit }, project_id) {
    const { data } = await query({
      query: gqlDeleteProject,
      variables: {
        id: project_id,
      },
    });
    const { delete_project_by_pk } = data;
    if (delete_project_by_pk) {
      return commit("DELETE_PROJECT", project_id);
    } else {
      throw { error: "No data", type: "deleteAccountProject" };
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_PROJECTS(state, content) {
    state.projects = [];
    content.forEach((d) => {
      state.projects.push({
        id: d.id,
        parent_project: d.parent_project,
        project: d.project,
        risk_summary: d.risk_summary,
        priority: d.priority,
        difficulty: d.difficulty,
        impact: d.impact,
      });
    });
  },
  INSERT_PROJECT(state, content) {
    if (!content.id) return state.projects.push(content);
    const elIndex = state.projects.findIndex((el) => el.id === content.id);

    if (elIndex !== -1) {
      state.projects.splice(elIndex, 1, content);
    } else {
      state.projects.push(content);
    }
  },
  DELETE_PROJECT(state, content) {
    const elIndex = state.projects.findIndex((f) => f.id === content);

    if (elIndex !== -1) {
      state.projects.splice(elIndex, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
