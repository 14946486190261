<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, provide, watch } from "vue";
import { useRoute } from "vue-router";

const menuTools = ref([]);
const submenuTools = ref([]);
provide("submenuTools", submenuTools);
provide("menuTools", menuTools);
const route = useRoute();

function getFirstTwoPathSegments(path) {
  const segments = path.split("/").filter(Boolean);
  return segments.slice(0, 2).join("/");
}
const updateSubmenuTools = (tool) => {
  const existingToolIndex = submenuTools.value.findIndex((t) => t.key === tool.key);
  if (existingToolIndex === -1) {
    submenuTools.value.push(tool);
  } else {
    submenuTools.value[existingToolIndex] = tool;
  }
};

provide("updateSubmenuTools", updateSubmenuTools);

watch(
  () => route.path,
  (newPath, oldPath) => {
    const newFirstTwoSegments = getFirstTwoPathSegments(newPath);
    const oldFirstTwoSegments = getFirstTwoPathSegments(oldPath);

    if (newFirstTwoSegments !== oldFirstTwoSegments) {
      menuTools.value = [];
      submenuTools.value = [];
    }
  }
);
</script>
