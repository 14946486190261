<template>
  <div ref="animationContainer" class="animation-background"></div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import * as THREE from "three";
import { useStore } from "vuex";

const store = useStore();
const VUE_APP_PRIMARY_COLOR = computed(() => store.getters["ui/VUE_APP_PRIMARY_COLOR"]);
const vertexShader = `
attribute float scale;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  gl_PointSize = scale * (300.0 / -mvPosition.z);
  gl_Position = projectionMatrix * mvPosition;
}
`;

const fragmentShader = `
uniform vec3 color;

void main() {
  float distance = length(gl_PointCoord - vec2(0.5, 0.5));
  float alpha = 1.0 - smoothstep(0.0, 0.475, distance);
  if (alpha < 0.01) discard;
  vec3 lightColor = mix(color, vec3(0.5, 1.0, 1.0), alpha);

  gl_FragColor = vec4(lightColor, alpha);
}
`;
const SEPARATION = 100,
  AMOUNTX = 100,
  AMOUNTY = 70;
let camera, scene, renderer;
let particles,
  count = 0;
let mouseX = 85,
  mouseY = -342;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

const animationContainer = ref(null);

onMounted(() => {
  init();
  animate();
});

onUnmounted(() => {
  window.removeEventListener("resize", onWindowResize);
  document.removeEventListener("mousemove", onDocumentMouseMove);
  document.removeEventListener("touchstart", onDocumentTouchStart);
  document.removeEventListener("touchmove", onDocumentTouchMove);
});

function init() {
  camera = new THREE.PerspectiveCamera(130, window.innerWidth / window.innerHeight, 1, 10000);
  camera.position.z = 1000;

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x0e1315);
  const positions = new Float32Array(AMOUNTX * AMOUNTY * 3);
  const scales = new Float32Array(AMOUNTX * AMOUNTY * 1);

  let i = 0;
  for (let ix = 0; ix < AMOUNTX; ix++) {
    for (let iy = 0; iy < AMOUNTY; iy++) {
      const x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
      const z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;

      positions[i * 3] = x;
      positions[i * 3 + 1] = 0;
      positions[i * 3 + 2] = z;

      scales[i++] = 8;
    }
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute("scale", new THREE.BufferAttribute(scales, 1));

  const material = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(`${VUE_APP_PRIMARY_COLOR.value}`) },
    },
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
  });

  particles = new THREE.Points(geometry, material);
  scene.add(particles);
  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  animationContainer.value.appendChild(renderer.domElement);

  document.addEventListener("mousemove", onDocumentMouseMove, false);
  document.addEventListener("touchstart", onDocumentTouchStart, false);
  document.addEventListener("touchmove", onDocumentTouchMove, false);

  window.addEventListener("resize", onWindowResize, false);
}

function onWindowResize() {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

function onDocumentMouseMove(event) {
  mouseX = (event.clientX - windowHalfX) * 0.05;
}

function onDocumentTouchStart(event) {
  if (event.touches.length === 1) {
    event.preventDefault();
    mouseX = event.touches[0].pageX - windowHalfX;
    mouseY = event.touches[0].pageY - windowHalfY;
  }
}

function onDocumentTouchMove(event) {
  if (event.touches.length === 1) {
    event.preventDefault();
    mouseX = event.touches[0].pageX - windowHalfX;
    mouseY = event.touches[0].pageY - windowHalfY;
  }
}

function animate() {
  requestAnimationFrame(animate);
  let i = 0;
  for (let ix = 0; ix < AMOUNTX; ix++) {
    for (let iy = 0; iy < AMOUNTY; iy++) {
      const x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
      const z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;

      const y = Math.sin((ix + count) * 0.3) * 50 + Math.sin((iy + count) * 0.5) * 50;

      particles.geometry.attributes.position.array[i * 3 + 1] = y;

      i++;
    }
  }

  particles.geometry.attributes.position.needsUpdate = true;

  render();
}

function render() {
  camera.position.x += (mouseX - camera.position.x) * 0.05;
  camera.position.y += (-mouseY - camera.position.y) * 0.05;
  camera.lookAt(scene.position);

  for (let ix = 0; ix < AMOUNTX; ix++) {
    for (let iy = 0; iy < AMOUNTY; iy++) {
      let index = ix * AMOUNTY + iy;
      if (particles[index] !== undefined) {
        let particle = particles[index];
        particle.position.y = Math.sin((ix + count) * 0.3) * 50 + Math.sin((iy + count) * 0.5) * 50;
        particle.scale.x = particle.scale.y = (Math.sin((ix + count) * 0.3) + 1) * 4 + (Math.sin((iy + count) * 0.5) + 1) * 1;
      }
    }
  }

  renderer.render(scene, camera);

  count += 0.1;
}
</script>

<style>
.animation-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}
</style>
