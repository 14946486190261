import gql_get_improvement_technique from "@/graphql/insight_improvement/get_improvement_technique.gql";
import gql_get_all from "@/graphql/insight_improvement/get_all.gql";
import gql_no_or_some from "@/graphql/insight_improvement/no_or_some.gql";
import { query } from "@/api";

const defaultStoreState = () => ({
  insight_improvement_techniques: null,
  insight_improvements: null,
  noOrSome: null,
});

const state = defaultStoreState();

const getters = {
  insightImprovements: (state) => state.insight_improvements,
  noOrSome: (state) => state.noOrSome,
  noOrSomeFiltered: (_state, getters, _rootState, rootGetters) => {
    const currentAccount = rootGetters["account/currentAccount"];
    const noOrSome = getters.noOrSome;
    if (!currentAccount) return null;
    if (!noOrSome) return null;
    return noOrSome.filter((f) => f.account_id === currentAccount);
  },
  computeImprovements: (_state, getters, _rootState, rootGetters) => {
    const threatIntelligenceType =
      rootGetters["account/threatIntelligenceType"];
    const accountTTPs = rootGetters["threat/accountTTPs"];
    const orpheusTTPs = rootGetters["ti/orpheusTTPs"];
    const TTPs =
      threatIntelligenceType === "orpheus" ? orpheusTTPs : accountTTPs;
    const insightImprovementTechniques = getters.insightImprovementTechniques;
    if (!insightImprovementTechniques) return null;
    if (!TTPs) return null;

    return TTPs.map((d) => {
      return {
        ...d,
        improvements: insightImprovementTechniques.filter(
          (f) => f.question_technique__technique_id === d.technique_id
        ),
      };
    });
  },
  scoredImprovements: (_state, getters) => {
    const computeImprovements = getters.computeImprovements;
    if (!computeImprovements) return null;
    let newObj = {};
    computeImprovements.forEach((d) => {
      if (d.improvements?.length) {
        d.improvements.forEach((i) => {
          if (newObj[i.improvement_id]) {
            newObj[i.improvement_id] = newObj[i.improvement_id] + d.count;
          } else {
            newObj[i.improvement_id] = d.count;
          }
        });
      }
    });
    return newObj;
  },
  scoredFinalImprovements: (state, getters) => {
    const scoredImprovements = getters.scoredImprovements;
    const insightImprovements = getters.insightImprovements;
    const insightImprovementTechniques = getters.insightImprovementTechniques;
    if (!insightImprovementTechniques) return null;
    if (!insightImprovements) return null;
    let noOrSomeFiltered = getters.noOrSomeFiltered;
    if (noOrSomeFiltered?.length) {
      noOrSomeFiltered = noOrSomeFiltered
        .filter((f) => f.deployment_status_text === "No")
        .map((d) => d.question_id);
    }
    if (!scoredImprovements) return null;
    let newArr = [];
    Object.entries(scoredImprovements).forEach((d) => {
      const tryFilter = insightImprovements.filter((f) => f.id === Number(d[0]))
        ? insightImprovements.filter((f) => f.id === Number(d[0]))[0]
        : null;
      if (tryFilter) {
        newArr.push({
          id: d[0],
          score: d[1],
          question_id: insightImprovementTechniques.filter(
            (f) => f.improvement_id === Number(d[0])
          )
            ? insightImprovementTechniques.filter(
                (f) => f.improvement_id === Number(d[0])
              )[0].question_id
            : [],
          ...tryFilter,
        });
      }
    });
    return newArr;
  },
  insightImprovementTechniques: (state) => state.insight_improvement_techniques,
};

const actions = {
  async getNoOrSome({ commit }, payload) {
    const { data } = await query({
      query: gql_no_or_some,
      variables: { account_id: payload },
    });
    if (data) {
      const { answer } = data;

      if (answer) {
        commit("SET_ANSWER_NO_OR_SOME", answer);
      }
    } else {
      throw { error: "No data", type: "getNoOrSome" };
    }
  },
  async getInsightTechniques({ commit }) {
    const { data } = await query({ query: gql_get_improvement_technique });
    if (data) {
      const { insight_improvement_technique } = data;

      if (insight_improvement_technique) {
        commit(
          "SET_INSIGHT_IMPROVEMENT_TECHNIQUES",
          insight_improvement_technique
        );
      }
    } else {
      throw { error: "No data", type: "getInsightTechniques" };
    }
  },
  async getAllImprovements({ commit }) {
    const { data } = await query({ query: gql_get_all });
    if (data) {
      const { insight_improvement } = data;

      if (insight_improvement) {
        commit("SET_INSIGHT_IMPROVEMENTS", insight_improvement);
      }
    } else {
      throw { error: "No data", type: "getAllImprovements" };
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_ANSWER_NO_OR_SOME: (state, content) => {
    state.noOrSome = content;
  },
  SET_INSIGHT_IMPROVEMENT_TECHNIQUES: (state, content) => {
    state.insight_improvement_techniques = content;
  },
  SET_INSIGHT_IMPROVEMENTS: (state, content) => {
    state.insight_improvements = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
