<template>
  <div class="flex w-full flex-col content-center justify-center border-gray-700 p-4">
    <ol class="flex items-center whitespace-nowrap" aria-label="Breadcrumb">
      <router-link to="/accounts" class="mr-1 flex text-sm text-gray-500 hover:text-blue-600">Home</router-link>
      <svg class="mr-1 mt-0.5 flex h-2.5 w-2.5 shrink-0 overflow-visible text-gray-400 dark:text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
      </svg>
      <li v-for="(segment, index) in segments" :key="index" class="flex text-sm">
        <router-link v-if="index < segments.length - 1" class="flex items-center text-gray-500 hover:text-blue-600" :to="segment.path">
          {{ segment.label }}
          <svg class="mx-1 mt-0.5 flex h-2.5 w-2.5 shrink-0 overflow-visible text-gray-400 dark:text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
          </svg>
        </router-link>
      </li>
    </ol>
    <div class="font-title text-2xl font-semibold text-white">{{ currentSegmentLabel.displayName }}</div>
    <div class="text-sm text-gray-500">{{ currentSegmentLabel.meta }}</div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
const store = useStore();
const router = useRouter();
const route = useRoute();
const subscription = computed(() => store.getters["tenant/subscription"]);
const lockedFeatures = computed(() => store.getters["tenant/lockedFeatures"]);
const lockedModules = computed(() => store.getters["tenant/lockedModules"]);
const isActiveTab = (tab) => {
  const normalizePath = (path) => (path.endsWith("/") ? path.slice(0, -1) : path);
  const normalizedCurrentPath = normalizePath(route.path);
  const normalizedTabTo = normalizePath(tab.to);
  if (normalizedCurrentPath === normalizedTabTo) {
    return true;
  }
  if (tab.children && tab.children.length > 0) {
    const isChildRouteActive = tab.children.some((child) => {
      const fullChildPath = normalizePath(`${normalizedTabTo}/${child.path}`);
      return normalizedCurrentPath.startsWith(`${fullChildPath}/`) || normalizedCurrentPath === fullChildPath;
    });

    return isChildRouteActive;
  }

  return false;
};

const currentTabs = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  const currentModule = subscription.value?.modules?.find((module) => module.route === currentPathSegment);

  if (!currentModule) return [];

  return currentModule.features.map((feature) => ({
    label: feature.display_name,
    icon: feature.icon,
    meta: feature.meta,
    to: `/${currentModule.route}${feature.path ? `/${feature.path}` : ""}`,
    children: feature.children || [],
  }));
});

const lockedTabs = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  let currentModule = subscription.value?.modules?.find((module) => module.route === currentPathSegment);

  if (!currentModule) {
    currentModule = lockedModules.value?.find((module) => module.route === currentPathSegment);
  }
  const lockedModuleFeatures = lockedFeatures.value?.filter((module) => module.module_id === currentModule?.id);

  if (!lockedModuleFeatures) return [];

  return lockedModuleFeatures.map((feature) => ({
    label: feature.display_name,
    icon: feature.icon,
    meta: feature.meta,
    to: `/${currentModule.route}${feature.path ? `/${feature.path}` : ""}`,
    children: feature.children || [],
  }));
});
const currentModuleInfo = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  return subscription.value?.modules?.find((module) => module.name === currentPathSegment) || {};
});
const segments = computed(() => {
  const pathSegments = route.path.split("/").filter((segment) => segment);

  return pathSegments.map((segment, index) => {
    const joinedPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    let foundTabOrFeature = null;
    currentTabs.value.some((tab) => {
      if (tab.to === joinedPath) {
        foundTabOrFeature = tab;
        return true;
      }
      const foundChild = tab.children?.find((child) => `${tab.to}/${child.path}` === joinedPath);
      if (foundChild) {
        foundTabOrFeature = {
          ...foundChild,
          label: foundChild.label || tab.label,
          meta: foundChild.meta || tab.meta,
          displayName: foundChild.display_name || tab.display_name,
        };

        return true;
      }
      return false;
    });
    if (!foundTabOrFeature && lockedTabs.value) {
      lockedTabs.value.some((tab) => {
        if (tab.to === joinedPath) {
          foundTabOrFeature = tab;
          return true;
        }
        const foundChild = tab.children?.find((child) => `${tab.to}/${child.path}` === joinedPath);

        if (foundChild) {
          foundTabOrFeature = {
            ...foundChild,
            label: foundChild.label || tab.label,
            meta: foundChild.meta || tab.meta,
            displayName: foundChild.display_name || tab.display_name,
          };

          return true;
        }
        return false;
      });
    }
    if (!foundTabOrFeature && index === pathSegments.length - 1) {
      foundTabOrFeature = {
        label: currentModuleInfo.value.display_name,
        meta: currentModuleInfo.value.meta,
        displayName: currentModuleInfo.value.display_name,
      };
    }
    return {
      label: foundTabOrFeature?.label || segment.charAt(0).toUpperCase() + segment.slice(1),
      path: joinedPath,
      meta: foundTabOrFeature?.meta,
      displayName: foundTabOrFeature?.displayName || segment.charAt(0).toUpperCase() + segment.slice(1),
    };
  });
});

const currentSegmentLabel = computed(() => {
  const lastSegment = segments.value.at(-1);
  return {
    meta: lastSegment?.meta,
    displayName: lastSegment?.label,
  };
});
</script>
