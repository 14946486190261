import { defineStore } from "pinia";
import { ref } from "vue";
import { query } from "@/api";

import get_org_data from "@/graphql/performance/get_org_data.gql";

export const useStructureStore = defineStore("structure", () => {
  const structure = ref(null);
  const flatStructure = ref(null);
  const orgs = ref(null);

  const createFlatStructure = (hierarchy) => {
    let flatList = [];

    hierarchy.forEach((org) => {
      // clone org and remove children property
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let { children, ...orgWithoutChildren } = org;
      flatList.push(orgWithoutChildren);

      if (org.children && org.children.length > 0) {
        let childrenFlatList = createFlatStructure(org.children);
        flatList = flatList.concat(childrenFlatList);
      }
    });

    return flatList;
  };

  function buildHierarchy(data, parentId = null) {
    let hierarchy = [];

    data.forEach((org) => {
      if (org.reports_to === parentId) {
        let children = buildHierarchy(data, org.org_id);
        let sortedById = children.sort((a, b) => a.org_id - b.org_id);
        let ordered = sortedById.sort((a, b) => {
          if (
            a.org_type === "VBU" &&
            ["Portfolio", "Sub-Portfolio", "Group"].includes(b.org_type)
          )
            return -1;
          if (
            a.org_type === "Group" &&
            ["Portfolio", "Sub-Portfolio"].includes(b.org_type)
          )
            return -1;
          return 0;
        });

        let orgObj = {
          id: org.account?.id || null,
          name: org.name,
          org_id: org.org_id,
          org_type: org.org_type,
          reports_to: org.reports_to,
          children: ordered,
        };

        hierarchy.push(orgObj);
      }
    });

    return hierarchy;
  }

  const getStructure = async () => {
    if (orgs.value) return;
    try {
      const { data } = await query({
        query: get_org_data,
      });

      if (data) {
        const { performance_organisation } = data;
        if (performance_organisation.length > 0) {
          orgs.value = performance_organisation;
          structure.value = buildHierarchy(orgs.value);
          flatStructure.value = createFlatStructure(structure.value);
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getChildAccountIds = (org_id) => {
    let ids = [];

    orgs.value.forEach((org) => {
      if (org.reports_to === org_id) {
        ids.push(org.account.id);
        ids.push(...getChildAccountIds(org.org_id));
      }
    });

    return ids;
  };

  const getParent = (org_id) => {
    let org = orgs.value?.find((x) => x.org_id === org_id);
    return org !== undefined
      ? orgs.value.find((x) => x.org_id === org?.reports_to)
      : null;
  };

  return {
    orgs,
    structure,
    getStructure,
    flatStructure,
    buildHierarchy,
    createFlatStructure,
    getChildAccountIds,
    getParent,
  };
});
