export function jwtParser() {
  const token = localStorage.getItem("jwt_token");

  if (!token) {
    return null;
  }

  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    jsonPayload = jsonPayload ? JSON.parse(jsonPayload) : {};
    jsonPayload = jsonPayload
      ? {
          ...jsonPayload,
          ...jsonPayload["https://hasura.io/jwt/claims"],
        }
      : {};

    return jsonPayload || {};
  } catch (error) {
    return null;
  }
}
