import { query } from "@/api";
import get_org_data from "@/graphql/performance/get_org_data.gql";

const state = {
  fairData: {
    tef: 0,
    v: 50,
    av: 0,
    tc: 50,
    cs: 50,
  },
};

const actions = {
  async getStructure({ commit, state }) {
    if (state.orgs) return;

    try {
      console.time("fetchData");
      const { data } = await query({
        query: get_org_data,
      });
      console.timeEnd("fetchData");

      if (data) {
        const { performance_organisation } = data;
        if (performance_organisation.length > 0) {
          commit("SET_ORGS", performance_organisation);
          const hierarchy = this.buildHierarchy(state.orgs);
          commit("SET_STRUCTURE", hierarchy);
          const flatList = this.createFlatStructure(state.structure);
          commit("SET_FLAT_STRUCTURE", flatList);
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {
  fairData: (state) => state.fairData,
};

const mutations = {
  SET_FAIR_DATA(state, newData) {
    state.fairData = newData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
