// compliance_baseline.js

import gql_get_structure from "@/graphql/caf/get_structure.gql";
import gql_caf_scoring from "@/graphql/caf/caf_scoring.gql";
import gql_tenant_framework_config from "@/graphql/framework/tenant_framework_config.gql";
import { query } from "@/api";

function calculateScore(section) {
  return section.questions.reduce((acc, obj) => {
    return acc + (obj.answers?.[0]?.caf_score || 0);
  }, 0);
}

const defaultStoreState = () => ({
  igps: [],
  cafScoring: [],
  tenantTargets: {},
  isError: null,
  isLoading: false,
  isInitialized: false,
});

const state = defaultStoreState();

const getters = {
  calculateAverages: (_, getters) => {
    let totalStandardised = 0;
    let totalCompanyTarget = 0;
    let totalProgressToTarget = 0;
    let totalCount = 0;

    const contributingOutcomes = getters.contributingOutcomes || {};
    for (let key in contributingOutcomes) {
      if (Object.prototype.hasOwnProperty.call(contributingOutcomes, key)) {
        contributingOutcomes[key].forEach((item) => {
          totalStandardised += item.standardised || 0;
          totalCompanyTarget += parseFloat(item.company_target) || 0;
          totalProgressToTarget += item.progress_to_target || 0;
          totalCount++;
        });
      }
    }

    return {
      averageStandardised: totalCount ? Math.round(totalStandardised / totalCount) : 0,
      averageCompanyTarget: totalCount ? Math.round(totalCompanyTarget / totalCount) : 0,
      averageProgressToTarget: totalCount ? Math.round(totalProgressToTarget / totalCount) : 0,
    };
  },
  complianceSectionsQuestionsAnswersAll: (_, __, _rootState, rootGetters) =>
    rootGetters["efficiency/complianceSectionsQuestionsAnswersAll"] || [],
  currentAccount: (_, __, _rootState, rootGetters) =>
    rootGetters["account/currentAccount"] || null,
  currentUser: (_, __, rootState) => rootState.user.user || null,
  contributingOutcomes: (state, getters) => {
    const complianceSectionsQuestionsAnswersAll =
      getters.complianceSectionsQuestionsAnswersAll;
    if (!complianceSectionsQuestionsAnswersAll) return null;

    return complianceSectionsQuestionsAnswersAll.reduce((accumulator, item) => {
      const { questions, caf_contributing_outcome: outcome, target } = item;
      const tenantTarget = state.tenantTargets?.[item.id] || null;
      const achievedQuestions = questions.filter(
        (q) => q.caf_igp_type === "achieved"
      );
      const rawResult = Math.round(
        (calculateScore(item) / item.total_possible_score) * 100
      );

      const companyTarget = target.length ? target[0].target_pct : null;

      const finalTarget = companyTarget
        ? companyTarget
        : tenantTarget
        ? tenantTarget
        : 100;
      const outcomeData = {
        contributing_outcome: outcome?.contributing_outcome,
        total_possible: item.total_possible_score,
        total_possible_calculated: achievedQuestions.length,
        objective: outcome?.objective,
        principle: outcome?.principle,
        principle_id: outcome?.principle_id,
        id: item.id,
        section_id: item.id || null,
        raw_result: rawResult,
        standardised: Math.min(100, Math.max(0, rawResult)),
        target_override: !!companyTarget,
        company_target: finalTarget,
        progress_to_target:
          rawResult && finalTarget
            ? Math.round((rawResult / finalTarget) * 100)
            : null,
      };

      if (!accumulator[outcome?.objective]) {
        accumulator[outcome?.objective] = [];
      }
      accumulator[outcome?.objective].push(outcomeData);

      return accumulator;
    }, {});
  },
};

const actions = {
  async fetchAllData({ dispatch, commit }) {
    commit("SET_LOADING", true);
    try {
      await Promise.all([
        dispatch("fetchIGPs"),
        dispatch("fetchTenantTargets"),
        dispatch("fetchScoring"),
      ]);
    } catch (error) {
      // Note that this will catch the first error thrown by any of the promises.
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
      commit("SET_INITIALIZED", true);
    }
  },

  async fetchIGPs({ commit, getters }) {
    const currentAccount = getters.currentAccount;
    if (!currentAccount) {
      commit("SET_ERROR", "Current account is not available");
      return;
    }

    const {
      data: { caf_objective },
    } = await query({
      query: gql_get_structure,
      variables: {
        account_id: currentAccount,
      },
    });
    commit("SET_IGPS", caf_objective);
  },

  async fetchTenantTargets({ commit }) {
    const {
      data: { tenant_framework_configuration },
    } = await query({
      query: gql_tenant_framework_config,
    });
    const targets = tenant_framework_configuration.length
      ? tenant_framework_configuration[0].section_targets
      : {};
    commit("SET_TENANT_TARGETS", targets);
  },

  async fetchScoring({ commit, getters }) {
    const currentAccount = getters.currentAccount;
    if (!currentAccount) {
      commit("SET_ERROR", "Current account is not available");
      return;
    }

    const {
      data: { caf_scoring },
    } = await query({
      query: gql_caf_scoring,
      variables: {
        account_id: currentAccount,
      },
    });
    commit("SET_CAF_SCORING", caf_scoring);
  },

  addBaselineTarget({ getters, dispatch }, evt) {
    const currentAccount = getters.currentAccount;
    if (!currentAccount) {
      throw new Error("Current account is not available");
    }

    return dispatch(
      "efficiency/storeUnsavedBaselineTarget",
      {
        ...evt,
        account_id: currentAccount,
      },
      { root: true }
    );
  },
};

const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_IGPS(state, igps) {
    state.igps = igps;
  },

  SET_CAF_SCORING(state, scoring) {
    state.cafScoring = scoring;
  },

  SET_TENANT_TARGETS(state, targets) {
    state.tenantTargets = targets;
  },

  SET_ERROR(state, error) {
    state.isError = error;
  },

  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_INITIALIZED(state, value) {
    state.isInitialized = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
