/* eslint-disable vue/multi-word-component-names */
import { createApp, provide, h } from "vue";
import { apolloClient, apolloProvider } from "./vue-apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-plus/dist/index.css";
import { createPinia } from "pinia";
import "./assets/index.css";
import PrimeVue from "primevue/config";
import vClickOutside from "click-outside-vue3";
import { VueMasonryPlugin } from "vue-masonry";
import ElementPlus from "element-plus";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import FloatingVue from "floating-vue";
import "@/assets/icon/css/icons.css";
import GridLayout from "vue3-drr-grid-layout";
import "floating-vue/dist/style.css";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// echarts themes start

import { registerTheme } from "echarts";
import arcoDark from "./assets/echarts-themes/arcoDark.json";

registerTheme("arcoDark", arcoDark);

// echarts themes end

// primevue start

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabMenu from "primevue/tabmenu";
import Knob from "primevue/knob";

// primevue end

const pinia = createPinia();
const app = createApp({
  setup() {
    provide(
      "authEndpoint",
      import.meta.env.VITE_APP_AUTH_URL
        ? import.meta.env.VITE_APP_AUTH_URL
        : import.meta.env.MODE === "development"
        ? "https://auth.arcocyber.com"
        : "https://auth.arcocyber.com"
    );
  },
  render: () => h(App),
}).use(apolloProvider);
app.use(router);
app.config.globalProperties.$authEndpoint = import.meta.env.VITE_APP_AUTH_URL
  ? import.meta.env.VITE_APP_AUTH_URL
  : import.meta.env.MODE === "development"
  ? "https://auth.arcocyber.com"
  : "https://auth.arcocyber.com";
app.use(VueMasonryPlugin);
app.use(pinia);
app.use(store);
app.use(GridLayout);
app.use(ElementPlus);
app.use(VueVirtualScroller);
app.use(FloatingVue, {
  themes: {
    arco: {
      triggers: ["click", "hover"],
      autoHide: true,
      placement: "right",
    },
  },
});
import "./assets/arcoDark.css";
app.use(vClickOutside);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Knob", Knob);
app.component("TabMenu", TabMenu);
app.component("VueDatePicker", VueDatePicker);
app.config.errorHandler = (err) => {
  console.error(err);
};

//core
import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";

import "element-plus/theme-chalk/display.css";

app.use(PrimeVue);

router.isReady().then(() => {
  app.mount("#app");
});
