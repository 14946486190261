import { query } from "@/api";
import getFlows from "@/graphql/flows/get_flows.gql";
import upsertFlowProgress from "@/graphql/flows/upsert_flow_progress.gql";
import fetchProgress from "@/graphql/flows/fetch_flow_progress.gql";
import completeFlow from "@/graphql/flows/complete_flow.gql";
import skipFlow from "@/graphql/flows/skip_flow.gql";

const state = () => ({
    flows: null,
    currentFlowProgress: null,
});

const mutations = {
    SET_FLOWS(state, flows) {
        state.flows = flows;
    },
    SET_CURRENT_FLOW_PROGRESS(state, flowProgress) {
        state.currentFlowProgress = flowProgress;
    },
};

const actions = {
    async fetchFlows({ commit }, modules) {
        try {
            const { data } = await query({
                query: getFlows,
                variables: { modules },
            });
            commit("SET_FLOWS", data.flows);
        } catch (error) {
            console.error("Error fetching flows:", error);
        }
    },

    async fetchFlowProgress({ commit }, { userId, flowId }) {
        try {
            const { data } = await query({
                query: fetchProgress,
                variables: { userId, flowId },
            });
            const progress = data.flow_progress_by_pk;
            commit("SET_CURRENT_FLOW_PROGRESS", progress);
            return progress;
        } catch (error) {
            console.error("Error fetching flow progress:", error);
        }
    },

    async startOrUpdateFlow(
        { commit, state },
        { userId, flowId, currentStageId, startedAt }
    ) {
        try {
            const { data } = await query({
                query: upsertFlowProgress,
                variables: {
                    userId,
                    flowId,
                    currentStageId,
                    startedAt,
                },
            });
            commit("SET_CURRENT_FLOW_PROGRESS", data.insert_flow_progress_one);
        } catch (error) {
            console.error("Error starting or updating flow:", error);
        }
    },

    async completeFlow({ commit, state }, { userId, flowId }) {
        try {
            await query({
                query: completeFlow,
                variables: { userId, flowId },
            });
            commit("SET_CURRENT_FLOW_PROGRESS", {
                ...state.currentFlowProgress,
                completed_at: new Date().toISOString(),
            });
        } catch (error) {
            console.error("Error completing flow:", error);
        }
    },

    async skipFlow({ commit }, { userId, flowId }) {
        try {
            await query({
                query: skipFlow,
                variables: { userId, flowId },
            });
            commit("SET_CURRENT_FLOW_PROGRESS", {
                ...state.currentFlowProgress,
                skipped_at: new Date().toISOString(),
            });
        } catch (error) {
            console.error("Error skipping flow:", error);
        }
    },
};

const getters = {
    flows: (state) => state.flows,
    currentFlowProgress: (state) => state.currentFlowProgress,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
