import { query } from "@/api";
import gql_get_all from "@/graphql/notification/get_all.gql";
import gql_delete_one from "@/graphql/notification/delete_one.gql";
import gql_update_one from "@/graphql/notification/update_one.gql";

const defaultStoreState = () => ({
  notifications: [],
});

const state = defaultStoreState();

const getters = {
  getNotifications(state) {
    return state.notifications;
  },
  getNotificationCount(state) {
    return state.notifications.length;
  },
};

const actions = {
  async updateNotification({ commit, state }, { id, isRead }) {
    const index = state.notifications.findIndex((n) => n.id === id);
    if (index === -1) return;

    // Optimistically update the local state
    commit("setNotificationRead", { index, isRead });

    try {
      await query({
        query: gql_update_one,
        variables: {
          id,
          isRead,
        },
      });
    } catch (error) {
      // If an error occurs, revert the optimistic update
      commit("setNotificationRead", { index, isRead: !isRead });
      throw error; // Re-throw the error for further handling if needed
    }
  },

  async deleteNotification({ commit, state }, id) {
    const index = state.notifications.findIndex((n) => n.id === id);
    if (index === -1) return;

    // Optimistically update the local state
    const removed = state.notifications.splice(index, 1);
    commit("removeNotification", index);

    try {
      await query({
        query: gql_delete_one,
        variables: {
          id,
        },
      });
    } catch (error) {
      // If an error occurs, revert the optimistic update
      state.notifications.splice(index, 0, removed[0]);
      throw error; // Re-throw the error for further handling if needed
    }
  },
  async fetchNotifications({ commit }, userId) {
    const response = await query({
      query: gql_get_all,
      variables: {
        userId,
      },
    });

    if (response && response.data && response.data.user_notification) {
      commit("setNotifications", response.data.user_notification);
    }
  },
};

const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  addNotification(state, notification) {
    state.notifications.unshift(notification); // Add to the beginning of the list
  },
  removeNotification(state, index) {
    state.notifications.splice(index, 1);
  },
  setNotificationRead(state, { index, isRead }) {
    if (state.notifications[index]) {
      state.notifications[index].is_read = isRead;
    }
  },
  clearAll(state) {
    state.notifications = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
