export function getProductsAndCapabilities(obj) {
  let result = [];

  for (let product_id in obj) {
    for (let capability_id in obj[product_id]) {
      result.push({
        product_id: { _eq: Number(product_id) },
        capability_id: { _eq: Number(capability_id) },
      });
    }
  }

  return result;
}

export const getMetricValue = (
  widgetConfig,
  widgetData,
  dataMode,
  previous = false
) => {
  const { alt_inference_field, inference_field, inference_field_previous } =
    widgetConfig;
  const { account, aggregated_children } = widgetData;
  const extractType = dataMode;

  const infField = previous ? inference_field_previous : inference_field;

  let finalValue;

  if (extractType === "one") {
    finalValue = getFinalValue(account?.data_concat?.[infField]);
  } else if (extractType === "agg") {
    finalValue = getFinalValue(aggregated_children?.data_concat?.[infField]);
  }

  // If the primary inference field does not yield a valid value and there are alternative fields to check
  if (
    !finalValue &&
    Array.isArray(alt_inference_field) &&
    alt_inference_field.length > 0
  ) {
    for (let altField of alt_inference_field) {
      if (extractType === "one") {
        finalValue = getFinalValue(account?.data_concat?.[altField]);
      } else if (extractType === "agg") {
        finalValue = getFinalValue(
          aggregated_children?.data_concat?.[altField]
        );
      }

      // Break out of the loop if a valid value is found
      if (finalValue) break;
    }
  }

  return finalValue || null;
};

export function prepareProductCapabilityData(arr, accountId) {
  let result = {};

  for (let item of arr) {
    const productId = Number(item.product_id);
    const capabilityId = Number(item.capability_id);

    if (!result[productId]) {
      result[productId] = {};
    }

    if (!result[productId][capabilityId]) {
      result[productId][capabilityId] = {
        account: null,
        aggregated_children: [],
      };
    }

    if (item.account_id === accountId) {
      result[productId][capabilityId].account = {
        ...item,
        data_concat: {
          ...item.data_custom,
          ...item.data_normalised,
        },
      };
    }

    result[productId][capabilityId].aggregated_children.push({
      ...item,
      data_concat: {
        ...item.data_custom,
        ...item.data_normalised,
      },
    });
  }

  for (let productId in result) {
    for (let capabilityId in result[productId]) {
      result[productId][capabilityId].aggregated_children =
        aggregatePerformanceData(
          result[productId][capabilityId].aggregated_children
        );
    }
  }
  return result;
}

export function aggregatePerformanceData(performanceData) {
  let result = {
    data_custom: {},
    data_normalised: {},
  };
  let count_custom = {};
  let count_normalised = {};
  let total_custom = {};
  let total_normalised = {};
  let frequency_custom = {};
  let frequency_normalised = {};

  const aggregate = (
    target,
    key,
    value,
    count,
    total,
    frequency,
    aggregationMethod
  ) => {
    switch (aggregationMethod) {
      case "sum":
        target[key] = (target[key] || 0) + value;
        break;
      case "average":
        count[key] = (count[key] || 0) + 1;
        target[key] =
          ((target[key] || 0) * (count[key] - 1) + value) / count[key];
        break;
      case "min":
        target[key] =
          target[key] === undefined ? value : Math.min(target[key], value);
        break;
      case "max":
        target[key] =
          target[key] === undefined ? value : Math.max(target[key], value);
        break;
      case "product":
        target[key] = target[key] === undefined ? value : target[key] * value;
        break;
      case "average_pct":
        total[key] = (total[key] || 0) + value;
        count[key] = (count[key] || 0) + 1;
        target[key] = (total[key] / count[key]) * 100;
        break;
      case "mode":
        frequency[key] = frequency[key] || {};
        frequency[key][value] = (frequency[key][value] || 0) + 1;
        target[key] = Object.keys(frequency[key]).reduce((a, b) =>
          frequency[key][a] > frequency[key][b] ? a : b
        );
        break;
      default:
        throw new Error(`Unsupported aggregation type: ${aggregationMethod}`);
    }
  };

  performanceData.forEach((item) => {
    let metricsMap = {};
    if (item.metrics) {
      item.metrics.forEach((metric) => {
        if (!metric.inference_field) return;
        metricsMap[metric.inference_field] = metric.aggregation_method;
        if (
          metric.alt_inference_field &&
          Array.isArray(metric.alt_inference_field) &&
          metric.alt_inference_field.length > 0
        ) {
          for (let altField of metric.alt_inference_field) {
            metricsMap[altField] = metric.aggregation_method;
          }
        }
      });
    }

    for (let [inferenceMethod, targetResult] of Object.entries(result)) {
      if (item.data_concat) {
        for (let key in item.data_concat) {
          if (metricsMap[key]) {
            let aggregationMethod = metricsMap[key];
            if (
              typeof item.data_concat[key] === "number" ||
              aggregationMethod === "mode"
            ) {
              let frequency =
                inferenceMethod === "data_custom"
                  ? frequency_custom
                  : frequency_normalised;
              aggregate(
                targetResult,
                key,
                item.data_concat[key],
                inferenceMethod === "data_custom"
                  ? count_custom
                  : count_normalised,
                inferenceMethod === "data_custom"
                  ? total_custom
                  : total_normalised,
                frequency,
                aggregationMethod
              );
            } else if (!targetResult[key]) {
              targetResult[key] = item.data_concat[key];
            }
          }
        }
      }
    }
  });
  return {
    data_custom: result.data_custom,
    data_normalised: result.data_normalised,
    data_concat: {
      ...result.data_custom,
      ...result.data_normalised,
    },
  };
}

export const comparePrevious = (current, previous, good) => {
  if (current === previous) {
    return "";
  }
  if (good === "up") {
    return previous < current
      ? "ai ai-up-arrow text-green-500"
      : "ai ai-down-arrow text-red-500";
  } else if (good === "down") {
    return previous > current
      ? "ai ai-down-arrow text-green-500"
      : "ai ai-up-arrow text-red-500";
  }
};

export const calculateDifference = (
  widgetConfig,
  widgetData,
  dataModeValue
) => {
  const current = getMetricValue(widgetConfig, widgetData, dataModeValue);
  const previous = getMetricValue(
    widgetConfig,
    widgetData,
    dataModeValue,
    true
  );

  if (isNaN(current) || isNaN(previous)) {
    return null;
  }

  return current - previous;
};

export function getAggregatedAccountIds(data, accountId) {
  let result = [];

  function traverse(node) {
    if (node.id === accountId || result.includes(node.id)) {
      result.push(node.id);
      for (let child of node.children) {
        result.push(child.id);
        traverse(child);
      }
    } else {
      for (let child of node.children) {
        traverse(child);
      }
    }
  }

  for (let item of data) {
    traverse(item);
  }

  return result;
}

export function nFormatter(num, digits = 0) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function checkIfTimestamp(valueNew) {
  // Check if the string matches the ISO format "YYYY-MM-DDTHH:MM:SS.sssZ"
  const isoPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:/;

  if (isoPattern.test(valueNew)) {
    return valueNew.slice(0, 10); // Return only the date part
  }
  return valueNew; // If not in the format, return the original string
}

export function getFinalValue(metric) {
  try {
    if (!isNaN(metric)) {
      const num = Number.isInteger(metric)
        ? metric
        : Number(Number(metric).toFixed(2));
      return num > 100000 ? nFormatter(num, 2) : num.toLocaleString("en-GB");
    }
    return checkIfTimestamp(metric);
  } catch (error) {
    console.error(error);
  }
}
