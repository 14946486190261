import accountAnswers from "@/graphql/answer/account_answers.gql";
import get_product_by_account from "@/graphql/answer/get_product_by_account.gql";
import accountCreateNew from "@/graphql/account/create_new.gql";
import get_account_detail from "@/graphql/account/get_account_detail.gql";
import get_account_detail_answers from "@/graphql/account/get_account_detail_answers.gql";
import get_account_detail_industry_threat_groups from "@/graphql/account/get_account_detail_industry_threat_groups.gql";
import get_account_detail_orpheus_threat_profile from "@/graphql/account/get_account_detail_orpheus_threat_profile.gql";
import get_account_detail_account_products from "@/graphql/account/get_account_detail_account_products.gql";
import initial_token_check from "@/graphql/tokens/initial_token_check.gql";
import set_token_state from "@/graphql/tokens/insert_token_state.gql";
import getValidTokens from "@/graphql/tokens/get_valid_tokens.gql";
import getTenantTokens from "@/graphql/tokens/get_tenant_tokens.gql";
import consumeTenantToken from "@/graphql/tokens/consume_token.gql";
import get_recently_viewed from "@/graphql/account/get_recently_viewed.gql";
import update_recently_viewed from "@/graphql/account/update_recently_viewed.gql";
import accountGetAll from "@/graphql/account/get_all.gql";
import get_integrations from "@/graphql/tool/get_integration_registry.gql";
import { query } from "@/api";

const defaultStoreState = () => ({
  loading: {
    currentAccountDetailAccountProducts: true,
  },
  threatModelOverride: null,
  accountListAll: [],
  recentAccountIds: [],
  currentAccount: null,
  currentAccountDetail: null,
  currentAccountDetailAnswers: null,
  currentAccountDetailIndustryThreatGroups: null,
  currentAccountDetailOrpheusThreatProfile: null,
  currentAccountDetailAccountProducts: null,
  accountAnswers: null,
  accountProducts: null,
  integrations: null,
  assessment_initial: null,
  tenantTokenOrders: [],
  valid_tokens: 0,
});

const state = defaultStoreState();

const getters = {
  totalTokens: (state) => {
    return state.tenantTokenOrders.reduce(
      (acc, order) => acc + order.tokens,
      0
    );
  },
  sortedTokenOrders: (state) => {
    const sortedOrders = [...state.tenantTokenOrders];
    return sortedOrders.sort(
      (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
    );
  },
  availableThreatModels: (_state, getters) => {
    let models = [
      {
        value: "arco",
        label: "Arco",
      },
    ];
    const currentAccountDetail = getters.currentAccountDetail;
    if (currentAccountDetail?.orpheus_sub)
      models.push({
        value: "orpheus",
        label: "Orpheus",
      });
    if (currentAccountDetail?.control_risks_sub)
      models.push({
        value: "control_risks",
        label: "Control Risks",
      });
    models.shift();

    return models;
  },
  currentAccountLastFramework: (_state, getters) => {
    const currentAccountDetail = getters.currentAccountDetail;
    return (
      currentAccountDetail.answer_history[0]?.answer?.question?.framework_id ||
      null
    );
  },
  currentAccountRef: (state) => state.currentAccountDetail?.ref,
  recentAccounts: (state) => {
    return state.accountListAll
      .filter((a) => state.recentAccountIds.some((id) => id === a.id))
      .sort(
        (a, b) =>
          state.recentAccountIds.indexOf(a.id) -
          state.recentAccountIds.indexOf(b.id)
      );
  },
  integrations: (state) => state.integrations,
  initialTokenCheck: (state) => state.initial_token_check,
  threatIntelligenceType: (state, getters) => {
    const threatModelOverride = state.threatModelOverride;
    if (threatModelOverride) return threatModelOverride;
    const currentAccountDetail = getters.currentAccountDetail;
    if (currentAccountDetail?.orpheus_sub) return "orpheus";
    if (currentAccountDetail?.control_risks_sub) return "control_risks";
    return "arco";
  },
  accountListAll: (state) => {
    const accountListAll = state.accountListAll;
    if (!accountListAll) return null;
    return accountListAll.map((d) => {
      return {
        ...d,
        company: d.company?.[0] ? d.company[0] : null,
      };
    });
  },
  currentAccount: (_state, _getters, _rootState, rootGetters) => {
    const activeAccount = rootGetters["user/activeAccount"];
    if (activeAccount !== null) return activeAccount;
    else return null;
  },
  currentAccountDetail: (state, getters) => {
    const answers = state.currentAccountDetailAnswers || null;
    const currentAccountDetail = state.currentAccountDetail;
    const currentAccountDetailOrpheusThreatProfile =
      getters.currentAccountDetailOrpheusThreatProfile;
    const currentAccountDetailIndustryThreatGroups =
      state.currentAccountDetailIndustryThreatGroups;
    if (!currentAccountDetail) return null;
    return {
      ...currentAccountDetail,
      answers,
      orpheus_threat_profile: currentAccountDetailOrpheusThreatProfile,
      industry_threat_groups: currentAccountDetailIndustryThreatGroups,
    };
  },
  currentAccountDetailAnswers: (state) => {
    const currentAccountDetailAnswers = state.currentAccountDetailAnswers;
    if (!currentAccountDetailAnswers || !currentAccountDetailAnswers.length)
      return [];
    return currentAccountDetailAnswers.map((d) => {
      return {
        ...d,
        section_id: d.question ? d.question.section_id : null,
        framework_id: d.question ? d.question.framework_id : null,
      };
    });
  },
  currentAccountDetailIndustryThreatGroups: (state) => {
    return state.currentAccountDetailIndustryThreatGroups;
  },
  currentAccountDetailOrpheusThreatProfile: (state) => {
    return state.currentAccountDetailOrpheusThreatProfile
      ? state.currentAccountDetailOrpheusThreatProfile[0]
      : null;
  },
  currentAccountDetailAccountProducts: (state) => {
    return state.currentAccountDetailAccountProducts;
  },
  currentAccountProducts(_state, getters) {
    const currentAccountDetail = getters.currentAccountDetail;
    if (!currentAccountDetail) return null;
    if (
      !currentAccountDetail.products ||
      currentAccountDetail.products.length === 0
    )
      return null;
    let newArr = [];
    currentAccountDetail.products.forEach((a) => {
      if (a.products && a.products.length > 0) {
        a.products.forEach((p) => {
          newArr.push(p.product);
        });
      }
    });
    return newArr.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
  },
};

const actions = {
  optimisticLoadAnswers({ commit }, content) {
    commit("OPTIMISTIC_LOAD_ANSWERS", content);
  },
  updateThreatModel({ commit }, content) {
    commit("SET_CURRENT_THREAT_MODEL", content);
  },
  async getAccountDetail({ commit, dispatch }, payload) {
    try {
      dispatch("loading/startLoading", "getAccountDetail", { root: true });
      const { data } = await query({
        query: get_account_detail,
        variables: payload,
      });
      const { account_by_pk } = data;
      if (account_by_pk) {
        const accountTemplate = account_by_pk?.performance_dashboard_template;
        const tenantTemplate =
          account_by_pk?.tenant_account?.[0]?.tenant
            ?.performance_dashboard_template;
        commit(
          "performance_dashboard/setSelectedTemplate",
          accountTemplate?.id || tenantTemplate?.id || null,
          {
            root: true,
          }
        );
        commit("SET_CURRENT_ACCOUNT_DETAIL", account_by_pk);
        dispatch("project/getAccountProjects", payload.account_id, {
          root: true,
        });
        dispatch(
          "account/getAccountDetailAccountProducts",
          payload.account_id,
          { root: true }
        );
        dispatch("account/getAccountDetailAnswers", payload.account_id, {
          root: true,
        });
        dispatch(
          "account/getAccountDetailOrpheusThreatProfile",
          payload.account_id,
          { root: true }
        );
        if (account_by_pk.industry) {
          dispatch(
            "account/getAccountDetailIndustryThreatGroups",
            account_by_pk.industry,
            { root: true }
          );
        }
      }
    } catch (error) {
      throw { error: error.message, type: "getAccontDetail" };
    } finally {
      dispatch("loading/stopLoading", "getAccountDetail", { root: true });
    }
  },
  async getAccountDetailAnswers({ commit, dispatch, rootGetters }, account_id) {
    const usrFrameworkState = rootGetters["user/userFrameworkState"]?.[
      account_id
    ]
      ? rootGetters["user/userFrameworkState"][account_id]
      : null;
    const currentAccountLastFramework =
      usrFrameworkState || rootGetters["account/currentAccountLastFramework"];
    const { data } = await query({
      query: get_account_detail_answers,
      variables: {
        account_id,
      },
    });
    if (data) {
      const { answer } = data;

      if (answer?.length) {
        commit("SET_CURRENT_ACCOUNT_DETAIL_ANSWERS", answer);
        dispatch(
          "efficiency/updateCurrentFramework",
          currentAccountLastFramework || answer[0].question?.framework_id,
          { root: true }
        );
      } else {
        commit("SET_CURRENT_ACCOUNT_DETAIL_ANSWERS", []);
      }
    } else {
      throw { error: "No data", type: "getAccountDetailAnswers" };
    }
  },
  async getAccountDetailOrpheusThreatProfile({ commit }, account_id) {
    const { data } = await query({
      query: get_account_detail_orpheus_threat_profile,
      variables: {
        account_id,
      },
    });

    if (data) {
      const { orpheus_threat_profile } = data;

      if (orpheus_threat_profile) {
        commit(
          "SET_CURRENT_ACCOUNT_DETAIL_ORPHEUS_THREAT_PROFILE",
          orpheus_threat_profile
        );
      }

      return orpheus_threat_profile;
    } else {
      throw { error: "No data", type: "getAccountDetailOrpheusThreatProfile" };
    }
  },
  async getAccountDetailAccountProducts({ commit }, account_id) {
    const { data } = await query({
      query: get_account_detail_account_products,
      variables: {
        account_id,
      },
    });
    if (data) {
      let { account_products, account_product_direct } = data;

      if (account_product_direct?.length) {
        account_product_direct.forEach((p) => {
          account_products.push({
            logo_new: p.product.logo_new,
            product_id: p.product_id,
            product_name: p.product.name,
            vendor_id: p.product.vendor?.id,
            vendor_logo: p.product.vendor?.logo,
            vendor_name: p.product.vendor?.name,
            capabilities: p.product.capabilities,
            account_id,
          });
        });
      }

      if (account_products) {
        commit("SET_CURRENT_ACCOUNT_DETAIL_ACCOUNT_PRODUCTS", account_products);
      }
    } else {
      throw { error: "No data", type: "getAccountDetailAccountProducts" };
    }
  },
  async getInitialTokenState({ commit }, account_id) {
    const { data } = await query({
      query: initial_token_check,
      variables: {
        account_id: account_id,
      },
    });
    if (data.token_assessment_state_by_pk) {
      commit("SET_INITIAL_TOKEN", true);
    } else {
      commit("SET_INITIAL_TOKEN", false);
    }
  },
  async setTokenState({ commit, dispatch }, account_id) {
    const { data } = await query({
      query: set_token_state,
      variables: {
        account_id: account_id,
      },
    });
    if (data?.insert_token_assessment_state_one.present) {
      dispatch("account/getInitialTokenState", account_id, { root: true });
    } else {
      commit("SET_INITIAL_TOKEN", false);
    }
  },
  async fetchTenantTokens({ commit }) {
    try {
      const { data } = await query({
        query: getTenantTokens,
      });
      if (data?.token_orders) {
        commit("SET_TENANT_TOKEN_ORDERS", data.token_orders);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async fetchAvailableTokens({ commit }) {
    try {
      const { data } = await query({
        query: getValidTokens,
      });
      if (data?.valid_tenant_tokens) {
        commit("SET_VALID_TOKENS", data.valid_tenant_tokens[0].total_tokens);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async consumeTenantToken({ state, commit, dispatch }, payload) {
    if (state.tenantTokenOrders.length === 0) return;
    try {
      const { data } = await query({
        query: consumeTenantToken,
        variables: {
          id: payload.orderId,
          last_action_obj: {
            action: "consume",
            account_id: payload.account_id,
          },
        },
      });

      if (data?.update_token_orders_by_pk?.tokens === 0) {
        const index = state.tenantTokenOrders.findIndex(
          (order) => order.id === payload.orderId
        );
        commit("REMOVE_TOKEN_ORDER", index);
      } else {
        const index = state.tenantTokenOrders.findIndex(
          (order) => order.id === payload.orderId
        );
        commit("UPDATE_TOKEN_ORDER", {
          index,
          tokens: data.update_token_orders_by_pk.tokens,
        });
        await dispatch("account/getInitialTokenState", payload.account_id, {
          root: true,
        });
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  },
  async getIntegrations({ commit }) {
    const { data } = await query({
      query: get_integrations,
    });
    if (data) {
      commit("SET_INTEGRATIONS", data.backend_services_integration_registry);
    } else {
      throw { error: "No data", type: "integrations" };
    }
  },
  async getAccountDetailIndustryThreatGroups({ commit }, standard_industry_id) {
    const { data } = await query({
      query: get_account_detail_industry_threat_groups,
      variables: {
        standard_industry_id,
      },
    });
    if (data) {
      const { industry_threat_group } = data;

      if (industry_threat_group) {
        commit(
          "SET_CURRENT_ACCOUNT_DETAIL_INDUSTRY_THREAT_GROUPS",
          industry_threat_group
        );
      }
    } else {
      throw { error: "No data", type: "getAccountDetailIndustryThreatGroups" };
    }
  },
  async addNewAccount({ dispatch }, payload) {
    const formData = payload;
    const { data } = await query({
      query: accountCreateNew,
      variables: {
        objects: {
          ...formData,
          name: formData.company_name,
        },
      },
    });
    if (data) {
      const { insert_account } = data;
      if (insert_account?.returning) {
        dispatch("getAccountAll");
        return insert_account.returning[0].id;
      }
    } else {
      throw { error: "No data", type: "addNewAccount" };
    }
  },
  async getAccountAll({ commit }, account_id = null) {
    commit("CLEAR_ACCOUNT_LIST");
    try {
      let where = null;
      if (account_id) {
        where = {
          where: {
            _or: [
              {
                tenant_account: {
                  tenant_id: {
                    _eq: account_id,
                  },
                },
              },
            ],
          },
        };
      }
      const { data } = await query({ query: accountGetAll, variables: where });
      const { account } = data;
      if (account) {
        let newArr = [];
        account.forEach((a) => {
          newArr.push(a);
        });
        commit("SET_ACCOUNT_LIST_ALL", newArr);
      }
    } catch (error) {
      return console.error(error);
    }
  },
  async getAccountProducts({ commit }, payload) {
    const { data } = await query({
      query: get_product_by_account,
      variables: {
        account_id: payload,
      },
    });
    if (data) {
      const { answer } = data;

      if (answer) {
        let newArr = [];
        answer.forEach((a) => {
          if (a.products && a.products.length > 0) {
            a.products.forEach((p) => {
              newArr.push(p.product);
            });
          }
        });
        commit(
          "SET_ACCOUNT_PRODUCTS",
          newArr.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          )
        );
      }
    } else {
      throw { error: "No data", type: "getAccountProducts" };
    }
  },
  async getRecentAccounts({ state, rootGetters }) {
    const { data } = await query({
      query: get_recently_viewed,
      variables: {
        id: rootGetters["user/userId"],
      },
    });
    if (data) {
      const recently_viewed = data.user[0].recently_viewed;
      state.recentAccountIds = recently_viewed;
    }
  },
  async updateRecentAccounts({ state, rootGetters }, account) {
    const idx = state.recentAccountIds.findIndex((id) => id === account.id);
    if (idx > -1) {
      state.recentAccountIds.splice(idx, 1);
    }
    state.recentAccountIds.unshift(account.id);
    if (state.recentAccountIds.length > 7) {
      state.recentAccountIds.pop();
    }
    const { data } = await query({
      query: update_recently_viewed,
      variables: {
        id: rootGetters["user/userId"],
        recently_viewed: state.recentAccountIds,
      },
    });
  },
  async removeRecentAccount({ state, rootGetters }, account_id) {
    const idx = state.recentAccountIds.findIndex((id) => id === account_id);
    if (idx === -1) return;
    state.recentAccountIds.splice(idx, 1);
    const { data } = await query({
      query: update_recently_viewed,
      variables: {
        id: rootGetters["user/userId"],
        recently_viewed: state.recentAccountIds,
      },
    });
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  OPTIMISTIC_LOAD_ANSWERS(state, content) {
    content.forEach((c) => {
      const foundIndex = state.currentAccountDetailAnswers.findIndex(
        (x) => x.id == c.id
      );
      if (foundIndex > -1) {
        state.currentAccountDetailAnswers[foundIndex] = {
          ...state.currentAccountDetailAnswers[foundIndex],
          ...c,
        };
      } else {
        state.currentAccountDetailAnswers.push(c);
      }
    });
  },
  SET_CURRENT_ACCOUNT_DETAIL_ACCOUNT_PRODUCTS(state, content) {
    state.currentAccountDetailAccountProducts = content;
  },
  SET_INTEGRATIONS(state, content) {
    state.integrations = content;
  },
  SET_CURRENT_ACCOUNT_DETAIL_ANSWERS(state, content) {
    state.currentAccountDetailAnswers = content;
  },
  SET_INITIAL_TOKEN(state, content) {
    state.initial_token_check = content;
  },
  SET_TENANT_TOKEN_ORDERS(state, orders) {
    state.tenantTokenOrders = orders;
  },
  REMOVE_TOKEN_ORDER(state, index) {
    state.tenantTokenOrders.splice(index, 1);
  },
  UPDATE_TOKEN_ORDER(state, { index, tokens }) {
    state.tenantTokenOrders[index].tokens = tokens;
  },
  SET_VALID_TOKENS(state, total_tokens) {
    state.valid_tokens = total_tokens;
  },
  SET_CURRENT_ACCOUNT_DETAIL_ORPHEUS_THREAT_PROFILE(state, content) {
    state.currentAccountDetailOrpheusThreatProfile = content;
  },
  SET_CURRENT_ACCOUNT_DETAIL_INDUSTRY_THREAT_GROUPS(state, content) {
    state.currentAccountDetailIndustryThreatGroups = content;
  },
  SET_CURRENT_THREAT_MODEL(state, content) {
    state.threatModelOverride = content;
  },
  ADD_NEW_ACCOUNT(state, content) {
    state.accountListAll.push(content);
  },
  SET_ACCOUNT_LIST_ALL(state, content) {
    Object.assign(state.accountListAll, content);
  },
  CLEAR_ACCOUNT_LIST(state) {
    Object.assign(state.accountListAll, {});
  },
  SET_CURRENT_ACCOUNT_DETAIL(state, content) {
    state.currentAccountDetail = content;
  },
  SET_ACCOUNT_ANSWERS(state, content) {
    state.acc = content;
  },
  SET_ACCOUNT_PRODUCTS(state, content) {
    state.accountProducts = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
