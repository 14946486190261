import { query } from "@/api";
import get_org_data from "@/graphql/performance/get_org_data.gql";

const defaultStoreState = () => ({
  orgs: null,
});

const state = defaultStoreState();

function createFlatStructure(hierarchy) {
  let flatList = [];

  hierarchy.forEach((org) => {
    // clone org and remove children property
    let { children, ...orgWithoutChildren } = org;
    flatList.push(orgWithoutChildren);

    if (org.children && org.children.length > 0) {
      let childrenFlatList = createFlatStructure(org.children);
      flatList = flatList.concat(childrenFlatList);
    }
  });

  return flatList;
}

function buildHierarchy(data, parentId = null) {
  let hierarchy = [];

  data.forEach((org) => {
    if (org.reports_to === parentId) {
      let children = buildHierarchy(data, org.org_id);
      let sortedById = children.sort((a, b) => a.org_id - b.org_id);
      let ordered = sortedById.sort((a, b) => {
        if (
          a.org_type === "VBU" &&
          ["Portfolio", "Sub-Portfolio", "Group"].includes(b.org_type)
        )
          return -1;
        if (
          a.org_type === "Group" &&
          ["Portfolio", "Sub-Portfolio"].includes(b.org_type)
        )
          return -1;
        return 0;
      });

      let orgObj = {
        id: org.account?.id || null,
        name: org.name,
        org_id: org.org_id,
        org_type: org.org_type,
        reports_to: org.reports_to,
        heatmap: org.heatmap,
        children: ordered,
      };

      hierarchy.push(orgObj);
    }
  });

  return hierarchy;
}

const actions = {
  async getStructure({ commit, state }) {
    if (state.orgs) return;

    try {
      const { data } = await query({
        query: get_org_data,
      });

      if (data) {
        const { performance_organisation } = data;
        if (performance_organisation.length > 0) {
          commit("SET_ORGS", performance_organisation);
          // const hierarchy = buildHierarchy(state.orgs);
          // commit("SET_STRUCTURE", hierarchy);
          // const flatList = createFlatStructure(state.structure);
          // commit("SET_FLAT_STRUCTURE", flatList);
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  },
  buildHierarchy(_, data, parentId = null) {
    let hierarchy = [];

    data.forEach((org) => {
      if (org.reports_to === parentId) {
        let children = this.buildHierarchy(data, org.org_id);

        let orgObj = {
          name: org.name,
          org_id: org.org_id,
          org_type: org.org_type,
          reports_to: org.reports_to,
          heatmap: org.heatmap,
          children: children,
        };

        hierarchy.push(orgObj);
      }
    });

    return hierarchy;
  },
  createFlatStructure(_, hierarchy) {
    let flatList = [];

    hierarchy.forEach((org) => {
      // clone org and remove children property
      let { children, ...orgWithoutChildren } = org;
      flatList.push(orgWithoutChildren);

      if (org.children && org.children.length > 0) {
        let childrenFlatList = this.createFlatStructure(org.children);
        flatList = flatList.concat(childrenFlatList);
      }
    });

    return flatList;
  },
};

const getters = {
  orgs: (state) => state.orgs,
  structure: (state) =>
    state.orgs?.length ? buildHierarchy(state.orgs) : null,
  flatStructure: (state, getters) =>
    getters.structure?.length ? createFlatStructure(getters.structure) : null,
};

const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_ORGS(state, orgs) {
    state.orgs = orgs;
  },
  SET_STRUCTURE(state, structure) {
    state.structure = structure;
  },
  SET_FLAT_STRUCTURE(state, flatStructure) {
    state.flatStructure = flatStructure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
