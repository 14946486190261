import getFiltersMetadata from "@/graphql/metadata/get_filters_metadata.gql";
import { query } from "@/api";

const defaultStoreState = () => ({
  metadata: {},
  answers: {
    account: "2900d0f6-ef2b-48e8-a31a-b84f95d27abe",
    framework: null,
    unhiddenSections: [],
    unhiddenQuestions: [],
    threatRelevance: [],
    technology: null,
    securityFunction: null,
    analytics: null,
    showIncomplete: false,
    contributingOutcome: null,
    objective: null,
    principle: null,
    ttps: null,
    ig: null,
  },
});

const state = defaultStoreState();

const getters = {
  filterMetadata: (state) => {
    if (!state.metadata) return null;
    return {
      filtersAnalytics: state.metadata?.filtersAnalytics
        ? state.metadata.filtersAnalytics[0].value
        : null,
      filtersTechnologies: state.metadata?.filtersTechnologies
        ? state.metadata.filtersTechnologies[0].value
        : null,
      filtersSecurityFunction: state.metadata?.filtersSecurityFunction
        ? state.metadata.filtersSecurityFunction[0].value
        : null,
      filtersReasonForNo: state.metadata?.filtersReasonForNo
        ? state.metadata.filtersReasonForNo[0].value
        : null,
      filtersThreatRelevance: state.metadata?.filtersThreatRelevance
        ? state.metadata.filtersThreatRelevance[0].value
        : null,
      filtersObjectives: state.metadata?.filtersObjectives
        ? state.metadata.filtersObjectives
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((d) => d.value)
        : null,
      filtersPrinciples: state.metadata?.filtersPrinciples
        ? state.metadata.filtersPrinciples
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((d) => d.value)
        : null,
      filtersContributingOutcomes: state.metadata?.filtersContributingOutcomes
        ? state.metadata.filtersContributingOutcomes
            .sort((a, b) =>
              `${a.caf_principle.id}${a.id}`.localeCompare(
                `${b.caf_principle.id}${b.id}`
              )
            )
            .map((d) => d.value)
        : null,
    };
  },
  answersFilters: (state) => state.answers,
  unhiddenSections(state) {
    if (!state.answers || !state.answers.unhiddenSections) return [];
    return state.answers.unhiddenSections;
  },
  unhiddenQuestions(state) {
    if (!state.answers || !state.answers.unhiddenQuestions) return [];
    return state.answers.unhiddenQuestions;
  },
};

const actions = {
  setFilterValue({ commit }, payload) {
    commit("SET_ANSWER_FILTER_VALUE", payload);
  },
  async getFiltersMetadata({ commit }) {
    const { data } = await query({
      query: getFiltersMetadata,
    });
    if (data) {
      commit("SET_FILTERS_METADATA", data);
    } else {
      throw { error: data, type: "No data" };
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_ANSWER_FILTER_VALUE(state, content) {
    if (!content.type) return;
    state.answers[content.type] = content.value;
  },
  SET_FILTERS_METADATA(state, content) {
    state.metadata = {
      ...state.metadata,
      ...content,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
