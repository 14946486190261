<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
const kburl = computed(() => store.getters["ui/kb_url"]);
const isModuleLocked = computed(() => {
  const routeName = router.currentRoute.value.name;
  const userSubscription = store.getters["tenant/subscription"];

  let matchedModuleOrFeature = null;

  userSubscription?.modules.some((module) => {
    if (module.route === routeName && module.locked) {
      matchedModuleOrFeature = module;
      return true;
    }
    if (module.features) {
      const feature = module.features.find((feature) => feature.name === routeName && feature.locked);

      if (feature) {
        matchedModuleOrFeature = module;
        return true;
      }
    }
    return false;
  });

  return matchedModuleOrFeature ? matchedModuleOrFeature : null;
});

const openKb = function () {
    window.open("https://arcocyber.com/arco-free-upgrade", "_blank").focus();
};
</script>

<template>
  <div v-if="isModuleLocked" class="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm">
    <div class="modal-bg w-3/4 rounded-lg border border-gray-700 bg-arco-dbg bg-cover px-9 pb-8 pt-12 lg:w-1/3">
      <div class="mb-4 flex flex-col space-y-2 text-start text-white">
        <h1 class="flex flex-row font-title text-2xl font-semibold tracking-tight">
          <div class="mr-2 flex w-fit items-center rounded-full"><img src="@/assets/arco_logo_only.png" class="w-8 text-center" alt="" /></div>
          Arco {{ isModuleLocked.locked_info.title }}
        </h1>
        <div class="flex flex-row py-4 text-sm">
          <p class="mr-1 text-gray-200">Because you're on our basic subscription, you don't have access to the {{ isModuleLocked.locked_info.title }} module, by upgrading your subscription you can benefit from the following features:</p>
        </div>
      </div>
      <div class="my-2 flex flex-col items-start rounded-md border border-blue-800 bg-arco-blue/10 p-4" v-for="(feature, index) in isModuleLocked.locked_info.features" :key="index">
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" class="mr-2 text-arco-blue" height="1.5em" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20.92 5.037a2.629 2.629 0 0 0-1.314-.966l-4.939-1.643a8.563 8.563 0 0 0-5.344 0L4.393 4.07a2.658 2.658 0 0 0-1.816 2.522v5.297a8.525 8.525 0 0 0 1.053 4.088a8.399 8.399 0 0 0 2.9 3.064l4.059 2.561a2.707 2.707 0 0 0 2.822 0l4.06-2.561a8.438 8.438 0 0 0 3.952-7.152V6.593a2.652 2.652 0 0 0-.502-1.556m-5.025 4.755l-3.673 3.644a1.809 1.809 0 0 1-.531.367c-.204.08-.42.123-.638.126a1.787 1.787 0 0 1-.638-.126a1.73 1.73 0 0 1-.541-.367L8.066 11.63a.966.966 0 1 1 1.373-1.363l1.614 1.614l3.45-3.46a.967.967 0 0 1 1.363 1.372z"
            /></svg
          ><span class="text-sm">{{ feature }}</span>
        </div>
      </div>
      <div class="flex justify-end">
        <button @click="openKb()" class="my-2 flex items-center rounded-md border border-gray-600 bg-arco-dbg p-2 px-4 text-sm text-white hover:bg-white/10">Upgrade Subscription</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-bg {
  background: #171b1e;
  background-image: url("/navbg.png");
  background-repeat: no-repeat;
  background-position: top top;
  background-size: 50% 50%;
}
</style>
