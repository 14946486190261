<template></template>

<script>
export default {
  async mounted() {
    try {
      this.$store.dispatch("user/updateJwt", {
        jwt_token: this.$route.query.jwt_token,
        jwt_token_expiry: this.$route.query.jwt_token_expiry,
        refresh_token: this.$route.query.refresh_token,
      });
      localStorage.setItem("jwt_token", this.$route.query.jwt_token);
      localStorage.setItem("jwt_expires", this.$route.query.jwt_token_expiry);
      try {
        await this.$store.dispatch("tenant/getTenantData");
      } catch (error) {}
      const homeComponent = this.$store.getters["tenant/userTenantDetail"].default_home;
      return this.$router.push({ name: homeComponent });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
