<template>
  <div></div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const homeComponents = computed(() => store.getters["user/homeComponents"]);
const msspView = computed(() => store.getters["user/msspView"]);
const userTenantDetail = computed(() => store.getters["tenant/userTenantDetail"]);

onMounted(() => {
  if (msspView.value) {
    router.push({ name: homeComponents.value?.mssp });
  } else if (userTenantDetail.value?.subscription?.name === "arco_basic") {
    router.push({ name: "ComplianceHome" });
  } else {
    router.push({ name: homeComponents.value?.account });
  }
});
</script>
